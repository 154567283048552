import React, { useState } from 'react';
import { Form, Input, Button, Typography, Layout, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

function PasswordResetPage() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();
  const [callMessage, messageHolder] = message.useMessage();

  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email)
        .then(() => {
          const close = callMessage.open({
            type: 'success',
            content: 'Password reset email sent. Please check your inbox.',
            duration: 10,
            onClick: () => close()
          });
          navigate('/');
        });
    } catch (error) {
      setError(error.message);
    }
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  return (
    <Layout
      style={{
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Typography.Title level={5} style={{ textAlign: 'center', fontWeight: '400' }}>
        Enter your email to reset your password.
      </Typography.Title>

      <Form
        name="reset_password"
        style={{
          textAlign: 'center',
          maxWidth: '300px',
          paddingTop: '32px'
        }}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your Email!' }]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder="Email"
            onChange={onChangeEmail}
          />
        </Form.Item>
        <Form.Item>
          <Typography type="danger" ellipsis={{ rows: 1 }}>
            {error}
          </Typography>
        </Form.Item>
        <Form.Item>
          <Button type="primary"
            onClick={handleResetPassword}
            htmlType="submit"
            style={{ width: '100%' }}
          >
            Send reset email
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="link"
            size='small'
            onClick={() => navigate('/login')}
            style={{ width: '100%' }}
          >
            Back to Log in
          </Button>
        </Form.Item>
      </Form>
      {messageHolder}
    </Layout>
  );
}

export default PasswordResetPage;
