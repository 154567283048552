import { FieldType } from './System.types';

/**
 * Represents the foundational field data types in the system.
 * These are used to build the built-in data types, and the org-level-config field types.
 */
export type FieldDataTypeId =
    | 'text'
    | 'longText'
    | 'richText'
    | 'percentage' | 'percent'
    | 'currency'
    | 'quantity'
    | 'number'
    | 'yesNo'
    | 'time'
    | 'date'
    | 'dateTime'
    | 'timeRange'
    | 'dateRange'
    | 'dateTimeRange'
    | 'multiSelect'
    | 'singleSelect'
    | 'address'
    | 'table'
    | 'list'
    | 'null'
    | 'file'
    | 'image'
    | 'video'
    | 'link'
    | 'email'
    | 'phone'
    | 'url'
    | 'ipAddress'
    | 'json'
    | 'xml'
    | 'html'
    | 'password'
    | 'qos';

/**
 * Represents the foundational field data types in the system.
 * These are used to build the built-in data types, and the org-level-config field types.
 */
export const fieldDataType: Record<FieldDataTypeId, FieldType> = {
    text: {
        fieldDataTypeId: 'text',
        description: {
            shortLabel: 'Text',
            shortDescription: 'A single line of text',
            longLabel: 'Text Field',
            longDescription: 'Stores a single line of text, suitable for names, titles, or short descriptions.'
        }
    },
    longText: {
        fieldDataTypeId: 'longText',
        description: {
            shortLabel: 'Long Text',
            shortDescription: 'Multiple lines of text',
            longLabel: 'Long Text Field',
            longDescription: 'Stores multiple lines of text, suitable for longer descriptions, comments, or notes.'
        }
    },
    richText: {
        fieldDataTypeId: 'richText',
        description: {
            shortLabel: 'Rich Text',
            shortDescription: 'Formatted text',
            longLabel: 'Rich Text Field',
            longDescription: 'Stores formatted text with styling and potentially embedded media.'
        }
    },
    percent: {
        fieldDataTypeId: 'percent',
        description: {
            shortLabel: 'Percent',
            shortDescription: 'Percentage value',
            longLabel: 'Percent Field',
            longDescription: 'Stores a percentage value.'
        }
    },
    percentage: {
        fieldDataTypeId: 'percentage',
        description: {
            shortLabel: 'Percentage',
            shortDescription: 'Percentage value',
            longLabel: 'Percentage Field',
            longDescription: 'Stores a percentage value.'
        }
    },
    currency: {
        fieldDataTypeId: 'currency',
        description: {
            shortLabel: 'Currency',
            shortDescription: 'Monetary value',
            longLabel: 'Currency Field',
            longDescription: 'Stores a monetary value with currency information.'
        }
    },
    quantity: {
        fieldDataTypeId: 'quantity',
        description: {
            shortLabel: 'Quantity',
            shortDescription: 'Numeric quantity',
            longLabel: 'Quantity Field',
            longDescription: 'Stores a numeric quantity, typically used for inventory or measurements.'
        }
    },
    number: {
        fieldDataTypeId: 'number',
        description: {
            shortLabel: 'Number',
            shortDescription: 'Numeric value',
            longLabel: 'Number Field',
            longDescription: 'Stores a numeric value, which can be an integer or a decimal number.'
        }
    },
    yesNo: {
        fieldDataTypeId: 'yesNo',
        description: {
            shortLabel: 'Yes/No',
            shortDescription: 'Boolean value',
            longLabel: 'Yes/No Field',
            longDescription: 'Stores a boolean value, typically represented as Yes or No.'
        }
    },
    time: {
        fieldDataTypeId: 'time',
        description: {
            shortLabel: 'Time',
            shortDescription: 'Time value',
            longLabel: 'Time Field',
            longDescription: 'Stores a time value without date information.'
        }
    },
    date: {
        fieldDataTypeId: 'date',
        description: {
            shortLabel: 'Date',
            shortDescription: 'Date value',
            longLabel: 'Date Field',
            longDescription: 'Stores a date value without time information.'
        }
    },
    dateTime: {
        fieldDataTypeId: 'dateTime',
        description: {
            shortLabel: 'Date & Time',
            shortDescription: 'Date and time value',
            longLabel: 'Date and Time Field',
            longDescription: 'Stores a date and time value, including timezone information.'
        }
    },
    timeRange: {
        fieldDataTypeId: 'timeRange',
        description: {
            shortLabel: 'Time Range',
            shortDescription: 'Time period',
            longLabel: 'Time Range Field',
            longDescription: 'Stores a time range with start and end times.'
        }
    },
    dateRange: {
        fieldDataTypeId: 'dateRange',
        description: {
            shortLabel: 'Date Range',
            shortDescription: 'Date period',
            longLabel: 'Date Range Field',
            longDescription: 'Stores a date range with start and end dates.'
        }
    },
    dateTimeRange: {
        fieldDataTypeId: 'dateTimeRange',
        description: {
            shortLabel: 'Date & Time Range',
            shortDescription: 'Date and time period',
            longLabel: 'Date & Time Range Field',
            longDescription: 'Stores a date and time range with start and end date-times.'
        }
    },
    multiSelect: {
        fieldDataTypeId: 'multiSelect',
        description: {
            shortLabel: 'Multi-Select',
            shortDescription: 'Multiple selections from options',
            longLabel: 'Multi-Select Field',
            longDescription: 'Allows selection of multiple values from a predefined list of options.'
        }
    },
    singleSelect: {
        fieldDataTypeId: 'singleSelect',
        description: {
            shortLabel: 'Single Select',
            shortDescription: 'Single selection from options',
            longLabel: 'Single Select Field',
            longDescription: 'Allows selection of a single value from a predefined list of options.'
        }
    },
    address: {
        fieldDataTypeId: 'address',
        description: {
            shortLabel: 'Address',
            shortDescription: 'Physical address',
            longLabel: 'Address Field',
            longDescription: 'Stores a physical address with multiple components like street, city, state, etc.'
        }
    },
    table: {
        fieldDataTypeId: 'table',
        description: {
            shortLabel: 'Table',
            shortDescription: 'Tabular data',
            longLabel: 'Table Field',
            longDescription: 'Stores structured data in a tabular format with rows and columns.'
        }
    },
    list: {
        fieldDataTypeId: 'list',
        description: {
            shortLabel: 'List',
            shortDescription: 'List of items',
            longLabel: 'List Field',
            longDescription: 'Stores a list of items, typically of the same type.'
        }
    },
    null: {
        fieldDataTypeId: 'null',
        description: {
            shortLabel: 'Null',
            shortDescription: 'Empty value',
            longLabel: 'Null Field',
            longDescription: 'Represents an intentionally empty or undefined value.'
        }
    },
    file: {
        fieldDataTypeId: 'file',
        description: {
            shortLabel: 'File',
            shortDescription: 'File attachment',
            longLabel: 'File Field',
            longDescription: 'Stores a reference to an uploaded file or document.'
        }
    },
    image: {
        fieldDataTypeId: 'image',
        description: {
            shortLabel: 'Image',
            shortDescription: 'Image file',
            longLabel: 'Image Field',
            longDescription: 'Stores a reference to an uploaded image file.'
        }
    },
    video: {
        fieldDataTypeId: 'video',
        description: {
            shortLabel: 'Video',
            shortDescription: 'Video file',
            longLabel: 'Video Field',
            longDescription: 'Stores a reference to an uploaded video file.'
        }
    },
    link: {
        fieldDataTypeId: 'link',
        description: {
            shortLabel: 'Link',
            shortDescription: 'Hyperlink',
            longLabel: 'Link Field',
            longDescription: 'Stores a hyperlink to an external resource.'
        }
    },
    email: {
        fieldDataTypeId: 'email',
        description: {
            shortLabel: 'Email',
            shortDescription: 'Email address',
            longLabel: 'Email Field',
            longDescription: 'Stores an email address.'
        }
    },
    phone: {
        fieldDataTypeId: 'phone',
        description: {
            shortLabel: 'Phone',
            shortDescription: 'Phone number',
            longLabel: 'Phone Field',
            longDescription: 'Stores a phone number.'
        }
    },
    url: {
        fieldDataTypeId: 'url',
        description: {
            shortLabel: 'URL',
            shortDescription: 'Web address',
            longLabel: 'URL Field',
            longDescription: 'Stores a web address or URL link.'
        }
    },
    ipAddress: {
        fieldDataTypeId: 'ipAddress',
        description: {
            shortLabel: 'IP Address',
            shortDescription: 'Internet Protocol address',
            longLabel: 'IP Address Field',
            longDescription: 'Stores an IP address (IPv4 or IPv6).'
        }
    },
    json: {
        fieldDataTypeId: 'json',
        description: {
            shortLabel: 'JSON',
            shortDescription: 'JSON data',
            longLabel: 'JSON Field',
            longDescription: 'Stores structured data in JSON format.'
        }
    },
    xml: {
        fieldDataTypeId: 'xml',
        description: {
            shortLabel: 'XML',
            shortDescription: 'XML data',
            longLabel: 'XML Field',
            longDescription: 'Stores structured data in XML format.'
        }
    },
    html: {
        fieldDataTypeId: 'html',
        description: {
            shortLabel: 'HTML',
            shortDescription: 'HTML content',
            longLabel: 'HTML Field',
            longDescription: 'Stores HTML content for rich text formatting.'
        }
    },
    password: {
        fieldDataTypeId: 'password',
        description: {
            shortLabel: 'Password',
            shortDescription: 'Secure password',
            longLabel: 'Password Field',
            longDescription: 'Stores a password securely, typically with masking and encryption.'
        }
    },
    qos: {
        fieldDataTypeId: 'qos',
        description: {
            shortLabel: 'Quality of Spec',
            shortDescription: 'Specification quality',
            longLabel: 'Quality of Specification Field',
            longDescription: 'Stores a measure of the quality or completeness of a specification.'
        }
    }
};


/**
 * Represents the built-in field types in the system.
 * These are used to build the built-in data types, and the org-level-config field types.
 */
export type BuiltInFieldTypeId = 
    'formNumber' |
    'projectName' |
    'projectStatus';

/**
 * Represents the built-in field types in the system.
 * These are used to build the built-in data types, and the org-level-config field types.
 */
export const builtInFieldType: Record<BuiltInFieldTypeId, FieldType> = {
    formNumber: {
        fieldTypeId: 'formNumber',
        fieldDataTypeId: 'text',
        description: {
            shortLabel: 'Form Number',
            longDescription: 'Unique identifier for the form',
        },
    },
    projectName: {
        fieldTypeId: 'projectName',
        fieldDataTypeId: 'text',
        description: {
            shortLabel: 'Project Name',
            longDescription: 'Name of the project',
        },
    },
    projectStatus: {
        fieldTypeId: 'projectStatus',
        fieldDataTypeId: 'singleSelect',
        description: {
            shortLabel: 'Project Status',
            longDescription: 'Status of the project',
        },
    },
};