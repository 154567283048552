import { Firestore, getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { firebaseConfig, environmentType } from '../config';

/**
 * Connect Firestore emulator on development environment
 * @param firestore Firestore instance
 */
export const connectEmulatorOnDev = async ( firestore: Firestore ) => {
  if (environmentType === 'development') {
      try {
          const response = await fetch('http://localhost:8080');
          if (response.ok) {
              connectFirestoreEmulator(firestore, 'localhost', 8080);
              console.log('Connected to Firebase Firestore DB emulator');
          }
      } catch (error) {
          console.log('Firebase Firestore DB emulator is not running');
      }
  }
};

/**
 * Initialize Firestore instance
 * @returns Firestore instance
 */
export const firestore: Firestore = getFirestore(
  initializeApp(firebaseConfig)
);

connectEmulatorOnDev(firestore);