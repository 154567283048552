import { FC } from 'react';
import {
    Input,
    InputNumber,
    DatePicker,
    Checkbox
} from 'antd';
import { Timestamp } from 'firebase/firestore';
import dayjs from 'dayjs';

interface CellEditProps {
    value: any;
    onChange: (value: any) => void;
    type: string;
    readOnly?: boolean;
}

const CellEdit: FC<CellEditProps> = ({ value, onChange, type, readOnly }) => {
    switch (type) {
        case 'number':
            return (
                <InputNumber
                    variant='borderless'
                    value={value}
                    onChange={onChange}
                    readOnly={readOnly}
                />
            );
        case 'timestamp':
            return (
                <DatePicker
                    variant='borderless'
                    showTime
                    value={value instanceof Timestamp ? dayjs(value.toDate()) : null}
                    onChange={(date) => onChange(date ? Timestamp.fromDate(date.toDate()) : null)}
                    disabled={readOnly}
                />
            );
        case 'boolean':
            return (
                <Checkbox
                    checked={value}
                    onChange={onChange}
                    disabled={readOnly}
                />
            );
        default:
            return (
                <Input
                    variant='borderless'
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    readOnly={readOnly}
                />
            );
    }
};

export default CellEdit;