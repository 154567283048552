import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import { Alert } from 'antd';
import { DataProvider } from './providers/DataProvider';
import { UserProvider } from './providers/UserProvider';
import { ThemeProvider } from './providers/ThemeProvider';
import { AIProvider } from './providers/AIProvider'

const rootElement = document.getElementById('root');

if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement as HTMLElement);

root.render(
  <React.StrictMode>
    <Alert.ErrorBoundary>
      <DataProvider>
        <UserProvider>
          <ThemeProvider>
            <AIProvider>
              <App />
            </AIProvider>
          </ThemeProvider>
        </UserProvider>
      </DataProvider>
    </Alert.ErrorBoundary>
  </React.StrictMode>
);
