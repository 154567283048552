import { 
    useCallback,
    useContext
} from 'react';
import {
    doc,
    getDoc,
    DocumentReference,
} from 'firebase/firestore';

import { DataContext } from '../../providers/DataProvider';
import { DocDataWithId, DocId } from '../../types/System.types';

/**
 * Interface for the useGet hook
 * @property {function} get - Function to get data from Firestore
 */
export interface UseGet {
    /**
     * Gets data from Firestore
     * @param path - Document path
     * @param docId - Document ID
     * @returns Document data with ID or null if not found
     */
    get: <T extends DocDataWithId = DocDataWithId>(
        path: string, 
        docId: DocId
    ) => Promise<T | null>;
}

/**
 * Hook for managing Firestore data get operations
 * @param firestore - Firestore instance
 * @returns Object with get function
 */
export const useGet = (): UseGet => {
    const { firestore } = useContext(DataContext);

    /**
     * Gets data from Firestore
     * @param path - Document path
     * @param docId - Document ID
     * @returns Data or null if not found
     */
    const get = useCallback(async <T extends DocDataWithId = DocDataWithId>(path: string, docId: DocId): Promise<T | null> => {
        try {
            const docRef: DocumentReference = doc(firestore, path, docId);
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
                return { docId: docSnapshot.id, ...docSnapshot.data() } as T;
            }
            return null;
        } catch (error) {
            console.error(`Error in get operation: ${error}`);
            return null;
        }
    }, [firestore]);

    return { get };
};