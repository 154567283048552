import { useState, useContext } from 'react';
import { Typography, Input, Button, Grid, Alert, Select, theme } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';

import FlexBox from '../atoms/FlexBox';
import { AIContext } from '../../providers/AIProvider';
import { UserContext } from '../../providers/UserProvider';

const Text = Typography.Text;
const { useToken } = theme;

export default function ModelInput() {
    const {
        settings,
        updateSettings
    } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [role, setRole] = useState<string>(settings?.role || 'Corporate Assistant');
    const screen = Grid.useBreakpoint();
    const { token } = useToken();
    const {
        // callAgent,
        // callModel,
        streamModel,
        clearMessages,
        roleOptions,
        inputPrompt,
        setInputPrompt,
    } = useContext(AIContext);

    const handleEnter = async () => {
        if (inputPrompt.trim() === '') return;

        setIsLoading(true);

        try {
            // await callModel(role, useCompanyInfo, inputPrompt);
            await streamModel(role, true, inputPrompt);
            setInputPrompt('');
        } catch (e) {
            if (e instanceof Error) {
                setError(e.message);
            }
            console.error(e);
        }
        finally {
            setIsLoading(false);
        }
    };

    return (
        <div
            style={{
                padding: 15,
                position: 'sticky',
                bottom: 0,
                width: '100%',
            }}
        >
            {error && (
                <Alert
                    message={`Error: ${error}`}
                    type="error"
                    showIcon
                    closable
                    onClose={() => setError(null)}
                    style={{ marginTop: 15 }}
                />
            )}
            <FlexBox
                wrap
                style={{
                    marginBottom: 10
                }}
            >

            </FlexBox>
            <FlexBox gap={5} alignStart>
                <FlexBox
                    column
                    gap={0}
                    style={{
                        width: '100%'
                    }}
                >
                    <FlexBox stretch>
                        <Select
                            value={role}
                            onChange={(value) => {
                                setRole(value);
                                updateSettings({ role: value });
                            }}
                            options={roleOptions}
                            variant="borderless"
                            size='small'
                            style={{
                                marginBottom: 5
                            }}
                        />
                    </FlexBox>
                    <FlexBox
                        stretch
                        alignStart
                    >
                        <FlexBox column gap={0}>
                            <Input.TextArea
                                id="inputPrompt"
                                size='large'
                                placeholder="Let's talk..."
                                allowClear
                                maxLength={8000}
                                value={inputPrompt}
                                onChange={(e) => setInputPrompt(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault();
                                        handleEnter();
                                    }
                                }}
                                autoSize={{ minRows: 1, maxRows: 10 }}
                                style={{
                                    borderColor: token.colorPrimaryBorder,
                                }}
                            />
                            <FlexBox stretch>
                                <Text type='secondary'>
                                    <small>
                                        Shift-enter for line feed.
                                    </small>
                                </Text>
                                <Button
                                    size='small'
                                    type='link'
                                    onClick={clearMessages}
                                >
                                    CLEAR
                                </Button>
                            </FlexBox>
                        </FlexBox>
                        <Button
                            size='large'
                            icon={<DoubleRightOutlined />}
                            onClick={handleEnter}
                            loading={isLoading}
                        >
                            {screen.sm && 'ENTER'}
                        </Button>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </div >
    );
}