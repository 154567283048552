import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
    Menu, 
    Button, 
    theme, 
    Dropdown, 
    Grid 
} from 'antd';
import type { MenuProps } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import Icon from './atoms/Icon';
import FlexBox from './atoms/FlexBox';
import Search from './atoms/Search';
import SettingsModal from './modals/SettingsModal';

export default function Navb() {
    const { token } = theme.useToken();
    const { useBreakpoint } = Grid;
    const { sm } = useBreakpoint();

    const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
    const { pathname } = useLocation();

    const selectedKey = pathname.split('/')[1].trim() || 'forms';

    const x = sm ? 20 : 10;

    const menuItems: MenuProps['items'] = [
        {
            key: 'forms',
            label: <Link to='/'>Forms</Link>,
        },
        {
            key: 'agent',
            label: <Link to='/agent'>Agent</Link>,
        },
        {
            key: 'entities',
            label: <Link to='/entities'>Entities</Link>,
        },
        {
            key: 'analytics',
            label: <Link to='/analytics'>Analytics</Link>,
        },
    ];

    return (
        <FlexBox
            stretch
            noGrow
            style={{
                paddingBlock: 10,
                paddingInline: x,
                position: 'relative',
            }}
        >
            <FlexBox
                justifyStart
                noGrow
                gap={x}
            >
                <img
                    src='/art/syncretic-logotype2.svg'
                    alt='Syncretic'
                    style={{
                        height: 29,
                        marginBottom: 1,
                    }}
                />
                {sm ? (
                    <Menu
                        mode="horizontal"
                        style={{
                            minWidth: "100%",
                            background: "none",
                            border: "none",
                        }}
                        selectedKeys={[selectedKey]}
                        items={menuItems}
                    />
                ) : (
                    <Dropdown 
                        menu={{ 
                            items: menuItems,
                            style: {
                                width: '100%'
                            },
                        }} 
                        trigger={['click']}
                    >
                        <Button type="text" icon={<MenuOutlined />} />
                    </Dropdown>
                )}
            </FlexBox>
            <FlexBox
                justifyEnd
                gap={10}
            >
                <Search />
                <Button
                    type='text'
                    icon={<Icon.Settings/>}
                    onClick={() => setIsSettingsModalVisible(true)}
                />
            </FlexBox>
            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: x,
                    right: x,
                    height: '1px',
                    backgroundColor: token.colorBorder,
                }}
            />
            <SettingsModal
                visible={isSettingsModalVisible}
                onClose={() => setIsSettingsModalVisible(false)}
            />
        </FlexBox>
    );
}