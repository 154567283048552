import { LazyExoticComponent, ComponentType, Suspense, lazy, createElement } from 'react';
import { IconProps as FeatherIconProps } from 'react-feather';

const iconCache: { [key: string]: LazyExoticComponent<ComponentType<any>> } = {};

interface IconProps extends Omit<FeatherIconProps, 'size'> {
    shift?: number;
    size?: number;
}

const Icon = new Proxy({}, {
    get: (target, prop: string) => {
        if (!iconCache[prop]) {
            iconCache[prop] = lazy(() =>
                import('react-feather').then((icons) => {
                    const Icon = (icons as any)[prop];
                    if (!Icon) {
                        throw new Error(`Icon ${prop} does not exist in 'react-feather'`);
                    }
                    return {
                        default: ({ shift = 3, size = 16, ...props }: IconProps) => <Icon
                            strokeWidth={1.75}
                            size={size}
                            style={{
                                marginTop: shift ? -shift : 0,
                                paddingTop: 0,
                                marginBottom: shift ? -shift : 0,
                            }}
                            {...props}
                        />
                    };
                })
            );
        }

        return (props: IconProps) => (
            <Suspense fallback={<div />}>
                {createElement(iconCache[prop], props)}
            </Suspense>
        );
    }
});

export default Icon as any;