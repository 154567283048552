import React, { useState, useContext } from 'react';
import { Typography, Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { AIContext, ActionType } from '../../providers/AIProvider';
import FlexBox from '../atoms/FlexBox';
import FlexCard from '../atoms/FlexCard';
import InputModal from '../modals/InputModal';

const Title = Typography.Title;
const Text = Typography.Text;

const ActionCards: React.FC = () => {
    const {
        actionCards
    } = useContext(AIContext);
    const [activeModals, setActiveModals] = useState<Record<number, boolean>>({});

    const handleAction = (actionType: ActionType, index: number) => {
        switch (actionType) {
            case ActionType.OpenModal:
                setActiveModals({ ...activeModals, [index]: true });
                break;
            default:
                setActiveModals({ ...activeModals, [index]: true });
                break;
        }
    };

    const closeModal = (index: number) => {
        setActiveModals({ ...activeModals, [index]: false });
    };


    return (
        <FlexBox 
            justifyEnd 
            alignEnd 
            wrap
            style={{
                minWidth: 300,
            }}
        >
            {actionCards && actionCards.map((card, index) => (
                <React.Fragment key={index}>
                    <FlexCard
                        onClick={() => handleAction(card.actionType, index)}
                        style={{
                            cursor: 'pointer',
                        }}
                    >
                        <FlexBox gap={25}>
                            <FlexBox column gap={5} alignStart>
                                <Title
                                    level={4}
                                    style={{
                                        margin: 0,
                                    }}
                                >
                                    {card.title}
                                </Title>
                                <Text>
                                    {card.content}
                                </Text>
                            </FlexBox>
                            <Button
                                key="action"
                                size='large'
                                shape='circle'
                                type='link'
                                icon={<ArrowRightOutlined />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleAction(card.actionType, index);
                                }}
                            />
                        </FlexBox>
                    </FlexCard>
                    <InputModal
                        card={card}
                        index={index}
                        open={activeModals[index]}
                        onClose={() => closeModal(index)}
                        handleOk={() => closeModal(index)}
                        handleCancel={() => closeModal(index)}
                    />
                </React.Fragment>
            ))}
        </FlexBox>
    );
};

export default ActionCards;