import React, { createContext, useState, useCallback, useMemo, FC } from 'react';
import { useBoundCollection } from '../use/data/useBoundCollection';
import { useBoundDoc } from '../use/data/useBoundDoc';
import { Metric, MetricGroupedBy, MetricRecord, DocId } from '../types/System.types';

interface AnalyticsContextValue {
  metrics: Metric[];
  selectedMetric: Metric | null;
  selectMetric: (metricId: DocId | undefined) => void;
  metricGroupedBy: MetricGroupedBy[];
  selectedGroupedMetric: MetricGroupedBy | null;
  selectGroupedMetric: (groupedMetricId: DocId | undefined) => void;
  metricRecords: MetricRecord[]; // Add this line
  loading: {
    metrics: boolean;
    metric: boolean;
    groupedMetrics: boolean;
    metricRecords: boolean;
  };
  error: {
    metrics: Error | null;
    metric: Error | null;
    groupedMetrics: Error | null;
    metricRecords: Error | null;
  };
}

export const AnalyticsContext = createContext<AnalyticsContextValue>({} as AnalyticsContextValue);

interface AnalyticsProviderProps {
  initialMetricId?: DocId;
  children: React.ReactNode;
}

export const AnalyticsProvider: FC<AnalyticsProviderProps> = ({ children, initialMetricId }) => {
  const [selectedMetricId, setSelectedMetricId] = useState<DocId | undefined>(initialMetricId);
  const [selectedGroupedMetricId, setSelectedGroupedMetricId] = useState<DocId | undefined>(undefined);

  const { 
    data: metrics = [], 
    loading: metricsLoading, 
    error: metricsError 
  } = useBoundCollection<Metric>({
    path: 'metrics',
    initialOrderBy: [{ field: 'meta.created', direction: 'desc' }],
  });

  const {
    data: selectedMetric,
    loading: metricLoading,
    error: metricError
  } = useBoundDoc<Metric>({
    path: 'metrics',
    docId: selectedMetricId,
    enabled: !!selectedMetricId,
  });

  const {
    data: metricGroupedBy = [],
    loading: groupedMetricsLoading,
    error: groupedMetricsError
  } = useBoundCollection<MetricGroupedBy>({
    path: selectedMetricId ? `metrics/${selectedMetricId}/metricGroupedBy` : '',
    enabled: !!selectedMetricId,
  });

  const {
    data: selectedGroupedMetric,
    loading: groupedMetricLoading,
    error: groupedMetricError
  } = useBoundDoc<MetricGroupedBy>({
    path: selectedMetricId ? `metrics/${selectedMetricId}/metricGroupedBy` : '',
    docId: selectedGroupedMetricId,
    enabled: !!selectedMetricId && !!selectedGroupedMetricId,
  });

  const {
    data: metricRecords = [],
    loading: metricRecordsLoading,
    error: metricRecordsError
  } = useBoundCollection<MetricRecord>({
    path: selectedMetricId && selectedGroupedMetricId
      ? `metrics/${selectedMetricId}/metricGroupedBy/${selectedGroupedMetricId}/metricRecords`
      : '',
    enabled: !!selectedMetricId && !!selectedGroupedMetricId,
  });

  const selectMetric = useCallback((metricId: DocId | undefined) => {
    setSelectedMetricId(metricId);
    setSelectedGroupedMetricId(undefined);
  }, []);

  const selectGroupedMetric = useCallback((groupedMetricId: DocId | undefined) => {
    setSelectedGroupedMetricId(groupedMetricId);
  }, []);

  const loading = { 
    metrics: metricsLoading, 
    metric: metricLoading, 
    groupedMetrics: groupedMetricsLoading || groupedMetricLoading,
    metricRecords: metricRecordsLoading 
  };
  const error = { 
    metrics: metricsError, 
    metric: metricError, 
    groupedMetrics: groupedMetricsError || groupedMetricError,
    metricRecords: metricRecordsError 
  };

  const contextValue = useMemo(
    () => ({
      metrics,
      selectedMetric: selectedMetric || null,
      selectMetric,
      metricGroupedBy,
      selectedGroupedMetric: selectedGroupedMetricId ? selectedGroupedMetric : null,
      selectGroupedMetric,
      metricRecords,
      loading,
      error,
    }),
    [metrics, selectedMetric, selectMetric, metricGroupedBy, selectedGroupedMetricId, selectedGroupedMetric, selectGroupedMetric, metricRecords, loading, error]
  );

  return <AnalyticsContext.Provider value={contextValue}>{children}</AnalyticsContext.Provider>;
};