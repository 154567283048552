import React, { FC, useContext, RefObject, useMemo } from 'react';
import { Typography, Skeleton } from 'antd';
import LayoutUI from './LayoutUI';
import UnmappedFieldsUI from './UnmappedFieldsUI';
import { FormContext } from '../../providers/FormProvider';
const { Title, Text } = Typography;

interface FormUIProps {
  contentRef: RefObject<HTMLDivElement>;
}

const FormUI: FC<FormUIProps> = React.memo(({ contentRef }) => {
  const {
    selectedForm,
    selectedFormVersion,
    formTypeOfSelectedForm,
  } = useContext(FormContext);

  const formContent = useMemo(() => {
    if (!selectedForm.data || !selectedFormVersion.data || !formTypeOfSelectedForm.data?.defaultUILayout) {
      return <Skeleton active />;
    }

    const { defaultUILayout } = formTypeOfSelectedForm.data;

    if (!defaultUILayout.structure) {
      return <Text>Error: No UI layout structure defined for this form.</Text>;
    }

    return (
      <>
        <Title
          level={5}
          style={{
            marginTop: 0,
            marginBottom: 5,
          }}
        >
          {selectedForm.data.title || 'Untitled Form'}
        </Title>
        <LayoutUI
          uiElement={defaultUILayout.structure}
          itemData={selectedFormVersion.data}
        />
        <UnmappedFieldsUI
          uiElement={defaultUILayout.structure}
          itemData={selectedFormVersion.data}
        />
      </>
    );
  }, [selectedForm.data, selectedFormVersion.data, formTypeOfSelectedForm.data]);

  return (
    <div
      ref={contentRef}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        alignSelf: 'stretch',
        gap: '2px',
      }}
    >
      {formContent}
    </div>
  );
});

FormUI.displayName = 'FormUI';

export default FormUI;