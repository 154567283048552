import { FC, useContext, useEffect } from 'react';
import { Skeleton, Typography, Alert, Switch, Divider } from 'antd';
import FlexCard from '../atoms/FlexCard';
import LayoutUI from './LayoutUI';
import UnmappedFieldsUI from './UnmappedFieldsUI';
import EntityRecordSelector from '../artifacts/EntityRecordSelector';
import FlexBox from '../atoms/FlexBox';
import { EntityContext } from '../../providers/EntityProvider';

const { Text } = Typography;

const EntityUI: FC = () => {
  const {
    selectedEntity: entity,
    selectedEntityRecord: entityRecord,
    loading,
    error,
    readOnly,
    setReadOnly,
    insideEntityContext
  } = useContext(EntityContext);

  if (loading.entity) {
    return <Skeleton active />;
  }

  if (error.entity) {
    return (
      <Alert
        message={error.entity?.message}
        type="error"
        style={{
          width: '100%',
        }}
      />
    );
  }

  if (!entity || !insideEntityContext) {
    return (
      <Alert
        message="Error: Missing entity data."
        type="error"
        style={{
          width: '100%',
        }}
      />
    );
  }

  return (
    <FlexCard
      justifyStart
      alignStart
      stretchCard
      growCard
      style={{
        marginBottom: 5,
      }}
      styles={{
        body: {
          width: '100%',
          paddingTop: 5,
        }
      }}
    >
      <FlexBox>
        <Text type="secondary">
          <small>
            {entity?.description?.shortLabel || entity?.description?.shortDescription || entity?.name}
          </small>
        </Text>
      </FlexBox>
      <EntityRecordSelector />
      <Divider
        orientation='left'
        orientationMargin={0}
        dashed
        style={{ margin: 0 }}
      >
        <Text type={readOnly ? 'secondary' : 'warning'}>
          <small>
            {readOnly ? 'Edit ' : 'Editing '}
          </small>
        </Text>
        <Switch
          size='small'
          checked={!readOnly}
          onChange={() => setReadOnly(!readOnly)}
        />
      </Divider>
      {entityRecord && entity?.defaultUILayout && (
        <LayoutUI
          uiElement={entity.defaultUILayout.structure}
          itemData={entityRecord}
        />
      )}
      {entityRecord && (
        <UnmappedFieldsUI
          uiElement={entity?.defaultUILayout?.structure}
          itemData={entityRecord}
        />
      )}
      {!entityRecord && (
        <Text>
          No entity record selected.
        </Text>
      )}
    </FlexCard>
  );
};

export default EntityUI;