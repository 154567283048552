/**
 * @file CLW.Config.types.ts
 * @description Configuration types and constants for Chicago Lightworks (CLW) system.
 * This file defines form states, record statuses, and template objects for forms, 
 * form versions, and layouts used in CLW's custom workflows.
 * These configurations maintain consistency and provide default structures throughout the application.
 * 
 * @module CLW.Config
 */

/**
 * Enum representing the possible states of a form in the system.
 */
export const formStateId = {
    Draft: "Draft",
    UnderReview: "UnderReview",
    Approved: "Approved",
    Revision: "Revision",
    Rejected: "Rejected",
    Sent: "Sent",
    Closed: "Closed",
    Archived: "Archived",
    Paid: "Paid",
    InTransit: "InTransit",
    Delivered: "Delivered"
} as const;
export type FormStateId = typeof formStateId[keyof typeof formStateId];

/*
 * Title and description of form states
 */
export const formStateDescription: Record<(FormStateId | "*"), {
    title: string;
    description: string;
    action?: string;
}> = {
    [formStateId.Draft]: {
        title: "Draft",
        description: "Form is in draft mode, editable by the creator",
        action: "Draft"
    },
    [formStateId.UnderReview]: {
        title: "Under Review",
        description: "Form is currently being reviewed by authorized personnel",
        action: "Review"
    },
    [formStateId.Approved]: {
        title: "Approved",
        description: "Form has been approved and is considered finalized",
        action: "Approve"
    },
    [formStateId.Revision]: {
        title: "Revision",
        description: "Form is being revised based on feedback",
        action: "Revise"
    },
    [formStateId.Rejected]: {
        title: "Rejected",
        description: "Form has been rejected and requires revision",
        action: "Reject"
    },
    [formStateId.Sent]: {
        title: "Sent",
        description: "Form has been sent to the relevant parties",
        action: "Send"
    },
    [formStateId.Closed]: {
        title: "Closed",
        description: "Form process is complete and closed",
        action: "Close"
    },
    [formStateId.Archived]: {
        title: "Archived",
        description: "Form is no longer active but retained for record-keeping",
        action: "Archive"
    },
    [formStateId.Paid]: {
        title: "Paid",
        description: "Payment for the form has been received and processed",
        action: "Pay"
    },
    [formStateId.InTransit]: {
        title: "In Transit",
        description: "Items or services are currently in transit",
        action: "Ship"
    },
    [formStateId.Delivered]: {
        title: "Delivered",
        description: "Items or services have been delivered to the destination",
        action: "Deliver"
    },
    "*": {
        title: "Unknown State",
        description: "The state of this form is not recognized",
        action: "Draft"
    }
};

/**
 * Enum representing the status of entities in the system.
 */
export enum RecordStatus {
    Active = "Active",
    Inactive = "Inactive",
    Pending = "Pending",
    Archived = "Archived"
}

export const formColumns = [
    { title: 'ID', key: 'docId' },
    { title: 'Title', key: 'title' },
    { title: 'Form Number', key: 'formNumber' },
    { title: 'Form Type', key: 'formType' },
    { title: 'Created', key: 'meta.created' },
] as const;

export type FormColumnKey = typeof formColumns[number]['key'];

/**
 * Enum representing the different types of forms available to the client.
 */
export const formTypeId = {
    CustomerSpecification: "CustomerSpecification",
    Quote: "Quote",
    PurchaseOrder: "PurchaseOrder",
    BillOfMaterials: "BillOfMaterials",
    HoldForRelease: "HoldForRelease",
    ChangeOrder: "ChangeOrder",
    Shipment: "Shipment",
    Submittal: "Submittal",
    Invoice: "Invoice",
    Payment: "Payment",
    CommissionStatement: "CommissionStatement"
} as const;
export type FormTypeId = typeof formTypeId[keyof typeof formTypeId];

/**
 * Title and description of form types
 */
export const formTypeDescription: Record<(FormTypeId | "*"), {
    title: string;
    description: string;
    code: string;
    verboseName: string;
}> = {
    [formTypeId.CustomerSpecification]: {
        title: "Specification",
        description: "Customer Specification Form",
        code: "SP",
        verboseName: "CustomerSpecification"
    },
    [formTypeId.Quote]: {
        title: "Quote",
        description: "Formal quote for products or services",
        code: "QT",
        verboseName: "Quote"
    },
    [formTypeId.PurchaseOrder]: {
        title: "Purchase Order",
        description: "Official order for products or services",
        code: "PO",
        verboseName: "PurchaseOrder"
    },
    [formTypeId.BillOfMaterials]: {
        title: "Bill of Materials",
        description: "List of raw materials, components, and quantities",
        code: "BM",
        verboseName: "BillOfMaterials"
    },
    [formTypeId.HoldForRelease]: {
        title: "Hold for Release",
        description: "Form to temporarily hold an order or process",
        code: "HR",
        verboseName: "HoldForRelease"
    },
    [formTypeId.ChangeOrder]: {
        title: "Change Order",
        description: "Form to request changes to an existing order or contract",
        code: "CO",
        verboseName: "ChangeOrder"
    },
    [formTypeId.Shipment]: {
        title: "Shipment",
        description: "Form for shipping and delivery information",
        code: "SH",
        verboseName: "Shipment"
    },
    [formTypeId.Submittal]: {
        title: "Submittal",
        description: "Form for submitting documents or samples for approval",
        code: "SB",
        verboseName: "Submittal"
    },
    [formTypeId.Invoice]: {
        title: "Invoice",
        description: "Bill for products or services rendered",
        code: "IN",
        verboseName: "Invoice"
    },
    [formTypeId.Payment]: {
        title: "Payment",
        description: "Record of payment received or made",
        code: "PY",
        verboseName: "Payment"
    },
    [formTypeId.CommissionStatement]: {
        title: "Commission Statement",
        description: "Statement detailing commission calculations and payments",
        code: "CS",
        verboseName: "CommissionStatement"
    },
    "*": {
        title: "Unknown Form Type",
        description: "The type of this form is not recognized",
        code: "UN",
        verboseName: "UnknownFormType"
    }
};

export type FormTypeVerboseName = typeof formTypeDescription[FormTypeId]['verboseName'];

export const formTypeCodeToVerboseName: Record<FormTypeId, FormTypeVerboseName> = Object.fromEntries(
    Object.entries(formTypeDescription).map(([code, desc]) => [code, desc.verboseName])
) as Record<FormTypeId, FormTypeVerboseName>;

export const formTypeVerboseNameToCode: Record<FormTypeVerboseName, FormTypeId> = Object.fromEntries(
    Object.entries(formTypeDescription).map(([code, desc]) => [desc.verboseName, code as FormTypeId])
) as Record<FormTypeVerboseName, FormTypeId>;


/**
 * Interface representing the Quality of Service (QoS) values.
 */
export interface QosValues {
    totalLines: number;
    clwRepresentedLines: number;
    clwSolelyListedLines: number;
    clwQuotedLines: number;
}

/**
 * Type representing the result of QoS validation.
 */
export type QosValidationResult = {
    isValid: boolean;
    message?: string;
    type: 'error' | 'warning' | 'success';
};