import { FC, useEffect, useContext, useMemo } from 'react';
import {
    Skeleton,
    Card,
    theme,
    Typography,
    Grid,
    message,
    List,
    Row,
    Col,
} from 'antd';

import FlexPage from '../components/atoms/FlexPage';
import Navb from '../components/Navb';
import { EntityContext, EntityProvider } from '../providers/EntityProvider';
import { Entity } from '../types/System.types';
import EntityUI from '../components/rendering/EntityUI';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const EntitiesPageContent: FC = () => {
    const { token } = theme.useToken();
    const screens = useBreakpoint();
    const [messageApi, contextHolder] = message.useMessage();

    const {
        entities,
        selectedEntity,
        selectEntity,
        loading,
        error,
    } = useContext(EntityContext);

    const listItemStyle = useMemo(() => (isSelected: boolean) => ({
        paddingBlock: 10,
        paddingInline: 10,
        cursor: 'pointer',
        backgroundColor: isSelected ? token.colorBgTextActive : 'transparent',
    }), [token.colorPrimary]);

    useEffect(() => {
        if (error.entities) {
            messageApi.error({
                content: `Error loading entities: ${error.entities.message}`,
                duration: 5,
            });
        }
    }, [error.entities, messageApi]);

    if (loading.entities) {
        return <Skeleton active />;
    }

    return (
        <FlexPage>
            {contextHolder}
            <Navb />
            <Row
                gutter={[16, 16]}
                style={{
                    width: '100%',
                    maxWidth: 2000,
                    padding: 20,
                }}
            >
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Card styles={{ body: { padding: 0 }}}>
                        <List
                            dataSource={entities}
                            renderItem={(entity: Entity) => (
                                <List.Item
                                    onClick={() => selectEntity(entity.docId)}
                                    style={listItemStyle(selectedEntity?.docId === entity.docId)}
                                >
                                    <Text>{entity.name}</Text>
                                </List.Item>
                            )}
                            style={{
                                maxHeight: screens.xs ? '200px' : '',
                                overflowY: 'auto' as const,
                                width: '100%',
                            }}
                        />
                    </Card>
                </Col>

                {selectedEntity && (
                    <Col xs={24} md={16} lg={18}>
                        <EntityUI key={selectedEntity.docId} />
                    </Col>
                )}
            </Row>
        </FlexPage>
    );
};

const EntitiesPage: FC = () => (
    <EntityProvider>
        <EntitiesPageContent />
    </EntityProvider>
);

export default EntitiesPage;