import {
  FC,
  createContext,
  ReactNode,
} from 'react';

import { firestore } from '../services/startFirestore';
import { DataContextType } from '../types/Data.types';

export const DataContext = createContext<DataContextType>({
  firestore: firestore,
});

export const DataProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <DataContext.Provider value={{ firestore }}>
      {children}
    </DataContext.Provider>
  );
};