import React, { createContext, useEffect, ReactNode } from 'react';
import useFirebaseAuth from '../use/useFirebaseAuth';
import { H } from 'highlight.run';
import { User } from 'firebase/auth';
import { loadSpace, InitOptions } from '@usersnap/browser';

import { 
    usersnapSpaceId,
    highlightId,
    environmentType,
    firebaseConfig,
} from '../config';
import { useBoundDoc } from '../use/data/useBoundDoc';
import { useSet } from '../use/data/useSet';

interface Settings {
    preferDarkMode?: string;
    role?: string;
    currentOrganization?: string;
}

export interface UserContextType {
    user: User | null;
    loading: boolean;
    login: (email: string, password: string) => Promise<void>;
    signup: (email: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
    fetchIdToken: () => Promise<string | null>;
    isLoggedIn: boolean;
    settings: Settings | null;
    updateSettings: (newSettings: Settings) => Promise<void>;
}

const defaultUserContextValue: UserContextType = {
    user: null,
    loading: false,
    login: async () => { },
    signup: async () => { },
    logout: async () => { },
    fetchIdToken: async () => null,
    isLoggedIn: false,
    settings: {
        preferDarkMode: 'system',
    },
    updateSettings: async () => { },
};

export const UserContext = createContext<UserContextType>(defaultUserContextValue);

interface UserProviderProps {
    children: ReactNode;
}

if (window.location.hostname.split(':')[0] !== 'localhost') {
    const h = H.init(highlightId, {
        serviceName: 'Syncretic System',
        tracingOrigins: true,
        manualStart: true,
        networkRecording: {
            enabled: true,
            recordHeadersAndBody: true,
        },
    });
    console.log(`H Analytics:`, h?.sessionSecureID);
} else {
    console.log('H Analytics disabled.');
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const { set } = useSet();
    const { user, loading, login, signup, logout, fetchIdToken } = useFirebaseAuth();
    const settings = useBoundDoc<Settings>({ 
        path: 'users', 
        docId: user?.uid, 
        enabled: !!user 
    });

    useEffect(() => {
        if (user && user.email) {
            if (window.location.hostname.split(':')[0] !== 'localhost') {
                H.identify(user.email, {
                    id: user.uid,
                    highlightDisplayName: user.email,
                });
                H.start()
                console.log(`H Analytics Started, user:`, user.uid, user.email);
            } else {
                console.log('H Analytics disabled (user).');
            }
    
            loadSpace(usersnapSpaceId).then((api) => {
              api.init({
                user: {
                    email: user?.email,
                    userId: user?.uid,
                },
                custom: {
                    displayName: user?.displayName,
                    userId: user?.uid,
                    email: user?.email,
                    firebaseProjectId: firebaseConfig.projectId,
                    environmentType,
                },
              } as InitOptions);
              console.log('Usersnap API initialized');
            }).catch((error) => {
              console.error('Failed to initialize Usersnap API', error);
            });
        }
    }, [user]);

    const updateSettings = async (newSettings: Settings) => {
        if (user) {
            await set('users', user.uid, newSettings);
        }
    };

    const userValue: UserContextType = {
        user,
        loading,
        login,
        signup,
        logout,
        fetchIdToken,
        isLoggedIn: user !== null,
        settings: settings.data,
        updateSettings,
    };

    return (
        <UserContext.Provider value={userValue}>
            {children}
        </UserContext.Provider>
    );
};