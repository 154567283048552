import { FC, ReactNode, createContext, useState, useEffect } from 'react';
import { useBoundCollection } from '../use/data/useBoundCollection';
import { useBoundDoc } from '../use/data/useBoundDoc';
import { Form, FormType, UILayout, FormVersion } from '../types/System.types';
import { useCheckDocAccess } from '../use/data/useCheckDocAccess';

export interface FormContextType {
    forms: ReturnType<typeof useBoundCollection<Form>>;
    formTypes: ReturnType<typeof useBoundCollection<FormType>>;
    selectedForm: ReturnType<typeof useBoundDoc<Form>>;
    selectedFormVersion: ReturnType<typeof useBoundDoc<FormVersion>>;
    formTypeOfSelectedForm: ReturnType<typeof useBoundDoc<FormType>>;
    selectedUILayout: ReturnType<typeof useBoundDoc<UILayout>>;
    formVersions: ReturnType<typeof useBoundCollection<FormVersion>>;
    uiLayouts: ReturnType<typeof useBoundCollection<UILayout>>;
    isLoading: boolean;
    selectForm: (formId: string | undefined) => void;
    selectFormVersion: (formVersionId: string | undefined) => void;
    selectUILayout: (uiLayoutId: string | undefined) => void;
    formVersionAccess: ReturnType<typeof useCheckDocAccess>;
    insideFormContext: boolean;
}

export const FormContext = createContext<FormContextType>({} as FormContextType);

export const FormProvider: FC<{
    children: ReactNode;
}> = ({ 
    children, 
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [selectedFormId, selectForm] = useState<string | undefined>(undefined);
    const [selectedFormVersionId, selectFormVersion] = useState<string | undefined>(undefined);
    const [selectedUILayoutId, selectUILayout] = useState<string | undefined>(undefined);

    const forms = useBoundCollection<Form>({
        path: 'forms',
        initialOrderBy: [{ field: 'meta.created', direction: 'desc' }],
        initialLimit: 50
    });

    const formTypes = useBoundCollection<FormType>({
        path: 'formTypes',
        initialOrderBy: [{ field: 'description.shortLabel', direction: 'asc' }],
        initialLimit: 50
    });

    const selectedForm = useBoundDoc<Form>({
        path: 'forms',
        docId: selectedFormId,
        enabled: !!selectedFormId
    });

    const formVersions = useBoundCollection<FormVersion>({
        path: `forms/${selectedFormId}/formVersions`,
        enabled: !!selectedFormId
    });

    const selectedFormVersion = useBoundDoc<FormVersion>({
        path: `forms/${selectedFormId}/formVersions`,
        docId: selectedFormVersionId,
        enabled: !!selectedFormId && !!selectedFormVersionId
    });

    const formTypeOfSelectedForm = useBoundDoc<FormType>({
        path: 'formTypes',
        docId: selectedForm.data?.formType,
        enabled: !!selectedForm.data?.formType
    });

    const selectedUILayout = useBoundDoc<UILayout>({
        path: `formTypes/${selectedForm.data?.formType}/uiLayouts`,
        docId: selectedUILayoutId,
        enabled: !!selectedForm.data?.formType && !!selectedUILayoutId
    });

    const uiLayouts = useBoundCollection<UILayout>({
        path: `formTypes/${selectedForm.data?.formType}/uiLayouts`,
        enabled: !!selectedForm.data?.formType
    });

    const formVersionAccess = useCheckDocAccess(
        (!selectedForm.loading && !selectedFormVersion.loading && selectedForm.data && selectedFormVersionId)
            ? `forms/${selectedForm.data.docId}/formVersions`
            : null,
        (!selectedForm.loading && !selectedFormVersion.loading && selectedFormVersionId) || null
    );

    useEffect(() => {
        const loadingStates = [
            forms.loading,
            formTypes.loading,
            selectedForm.loading,
            selectedFormVersion.loading,
            formTypeOfSelectedForm.loading,
            selectedUILayout.loading,
            formVersions.loading,
            uiLayouts.loading,
            formVersionAccess.loading
        ];
        setIsLoading(loadingStates.some(state => state));
    }, [
        forms.loading, 
        formTypes.loading, 
        selectedForm.loading, 
        selectedFormVersion.loading, 
        formTypeOfSelectedForm.loading, 
        selectedUILayout.loading, 
        formVersions.loading, 
        uiLayouts.loading, 
        formVersionAccess.loading
    ]);

    const value = {
        forms,
        formTypes,
        selectedForm,
        selectedFormVersion,
        formTypeOfSelectedForm,
        selectedUILayout,
        formVersions,
        uiLayouts,
        selectForm,
        selectFormVersion,
        selectUILayout,
        isLoading,
        formVersionAccess,
        insideFormContext: true
    };

    return (
        <FormContext.Provider value={value}>
            {children}
        </FormContext.Provider>
    );
};