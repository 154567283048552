import React, { CSSProperties, FC, ReactNode } from 'react';
import { Card } from 'antd';

interface FlexCardProps extends React.ComponentProps<typeof Card> {
  children: ReactNode;
  style?: CSSProperties;
  row?: boolean;
  wrap?: boolean;
  justifyStart?: boolean;
  justifyEnd?: boolean;
  justifyCenter?: boolean;
  alignStart?: boolean;
  alignEnd?: boolean;
  alignCenter?: boolean;
  growCard?: boolean;
  grow?: boolean;
  stretchCard?: boolean;
  stretch?: boolean;
  gap?: number;
  padding?: string | number;
  bodyStyle?: CSSProperties;
}

const FlexCard: FC<FlexCardProps> = ({
  children,
  style,
  row = false,
  wrap = false,
  justifyStart = false,
  justifyEnd = false,
  justifyCenter = false,
  alignStart = false, 
  alignEnd = false,
  alignCenter = false,
  growCard = false,
  grow = false,
  stretchCard = false,
  stretch = false,
  gap,
  padding,
  bodyStyle = {},
  ...props
}) => {
  return (
    <Card
      size="small"
      style={{
        display: 'flex',
        flexGrow: growCard ? 1 : 0,
        alignSelf: stretchCard ? 'stretch' : undefined,
        padding: padding,
        minWidth: 250,
        alignItems: alignStart
          ? 'flex-start'
          : alignEnd
          ? 'flex-end'
          : alignCenter
          ? 'center'
          : 'flex-start',
        ...style,
      }}
      styles={{
        body: {
          display: 'flex',
          flexDirection: row ? 'row' : 'column',
          justifyContent: justifyStart
            ? 'flex-start'
            : justifyEnd
            ? 'flex-end'
            : justifyCenter
            ? 'center'
            : 'space-between',
          alignItems: alignStart
            ? 'flex-start'
            : alignEnd
            ? 'flex-end'
            : alignCenter
            ? 'center'
            : 'flex-start',
          flexWrap: wrap ? 'wrap' : 'nowrap',
          flexGrow: grow ? 1 : 0,
          alignSelf: stretch ? 'stretch' : undefined,
          gap: gap === 0 ? 0 : gap || 10,
          width: '100%',
          ...bodyStyle,
        }
      }}
      {...props}
    >
      {children}
    </Card>
  );
};

export default FlexCard;