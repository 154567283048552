import { FC, CSSProperties, useState } from 'react';
import { useSearchBox, useHits } from 'react-instantsearch';
import { AutoComplete, Input, Typography, Button } from 'antd';
import { SearchOutlined, CloseCircleOutlined, EnterOutlined } from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';
import FlexBox from './FlexBox';

const T: FC<React.ComponentProps<typeof Typography.Text>> = ({ style, ...props }) => (
  <Typography.Text
    style={{
      margin: 0,
      lineHeight: 1,
      ...style,
    }}
    {...props}
  />
);

/**
 * Props for the Search component.
 */
interface SearchProps {
  /** Placeholder text for the search input */
  placeholder?: string;
  /** Custom styles for the AutoComplete component */
  autoCompleteStyle?: CSSProperties;
  /** Custom styles for the Input component */
  inputStyle?: CSSProperties;
  /** Callback function when an option is selected */
  onSelect?: (value: string) => void;
  /** Controlled value of the search input */
  value?: string;
  /** Callback function when the search input changes */
  onChange?: (value: string) => void;
  /** Whether the search input should auto focus */
  autoFocus?: boolean;
}

interface SearchItemProps {
  item: any;
}

/**
 * Renders a single search result item.
 * @param {SearchItemProps} props - The props for the SearchItem component.
 * @returns {JSX.Element} A link containing the search result information.
 */
const SearchItem: FC<SearchItemProps> = ({ item }) => (
  <Link to={`${item?.searchResultURL}`}>
    <FlexBox column noGrow alignStart>
      <FlexBox>
        <T>
          {item?.searchResultTitle || item?.title || item?.name || item?.objectID}
        </T>
      </FlexBox>
      <FlexBox>
        <T type='secondary'>
          {item?.collectionNameParent || item?.collectionName}&mdash;{item.state && item?.state || item?.status}
        </T>
      </FlexBox>
    </FlexBox>
  </Link>
);
export { SearchItem };

/**
 * Renders a search input field.
 * @param {SearchProps} props - The props for the SearchInput component.
 * @returns {JSX.Element} An Input component for search functionality.
 */
const SearchInput: FC<SearchProps> = ({
  placeholder = 'Search...',
  inputStyle,
  value,
  onChange,
  autoFocus = false
}) => {
  const { refine } = useSearchBox();
  const navigate = useNavigate();

  return (
    <Input
      autoFocus={autoFocus}
      variant='filled'
      style={inputStyle}
      placeholder={placeholder}
      prefix={<SearchOutlined />}
      value={value}
      onChange={(e) => {
        onChange?.(e.target.value);
        refine(e.target.value);
      }}
      onPressEnter={() => navigate(`/search?q=${encodeURIComponent(value?.trim() || '')}`)}
      allowClear={{ clearIcon: <CloseCircleOutlined /> }}
    />
  );
};
export { SearchInput };

/**
 * Renders a search component with autocomplete functionality.
 * @param {SearchProps} props - The props for the Search component.
 * @returns {JSX.Element} An AutoComplete component with search functionality.
 */
const Search: FC<SearchProps> = ({
  placeholder = 'Search...',
  autoCompleteStyle,
  inputStyle,
  onSelect,
}) => {
  const { refine } = useSearchBox();
  const { items } = useHits();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');

  const options = items.map(item => ({
    value: item.objectID,
    label: <SearchItem item={item} />
  }));

  /**
   * Handles the enter key press event.
   * Navigates to the search results page and clears the search input.
   */
  const handleEnter = () => {
    navigate(`/search?q=${encodeURIComponent(searchValue.trim())}`);
    setSearchValue('');
  };

  return (
    <AutoComplete
      options={options}
      onSelect={onSelect}
      popupMatchSelectWidth={false}
      style={{
        minWidth: 120,
        maxWidth: 500,
        width: '100%',
        ...autoCompleteStyle
      }}
      dropdownStyle={{
        maxWidth: 200
      }}
      value={searchValue}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <div style={{ float: 'right', position: 'relative' }}>
            <Button
              style={{
                position: 'absolute',
                bottom: -35,
                right: -4,
              }}
              type='dashed'
              size='small'
              onClick={handleEnter}
            >
              View full results<EnterOutlined />
            </Button>
          </div>
        </div>
      )}
    >
      <Input
        variant='filled'
        style={{
          width: '100%',
          ...inputStyle
        }}
        placeholder={placeholder}
        value={searchValue}
        prefix={<SearchOutlined />}
        onChange={(e) => {
          setSearchValue(e.target.value);
          refine(e.target.value);
        }}
        onPressEnter={handleEnter}
        allowClear={{ clearIcon: <CloseCircleOutlined /> }}
      />
    </AutoComplete>
  );
};

export default Search;