import React, { FC, ReactNode } from 'react';
import { Layout, LayoutProps } from 'antd';

interface FlexPageProps extends LayoutProps {
    children: ReactNode;
    style?: React.CSSProperties;
}

const FlexPage: FC<FlexPageProps> = ({ children, style, ...props }) => (
    <Layout
        style={{
            display: 'flex',
            minHeight: '100%',
            width: '100%',
            margin: '0 auto',
            ...style,
        }}
        {...props}
    >
        {children}
    </Layout>
);

export default FlexPage;