import { Input, InputNumber, Switch, DatePicker, Typography } from 'antd';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';

export const renderByValueType = (
  value: any,
  fieldId: string,
  canWrite: boolean,
  handleFieldChange: (value: any) => void
) => {
  switch (typeof value) {
    case 'string':
      return (
        <Input
          value={value}
          readOnly={!canWrite}
          onChange={(e) => handleFieldChange(e.target.value)}
        />
      );
    case 'number':
      return (
        <InputNumber
          value={value}
          readOnly={!canWrite}
          onChange={handleFieldChange}
        />
      );
    case 'boolean':
      return (
        <Switch
          checked={value}
          disabled={!canWrite}
          onChange={handleFieldChange}
        />
      );
    case 'object':
      if (Array.isArray(value)) {
        return (
          <Input.TextArea
            value={JSON.stringify(value)}
            readOnly={!canWrite}
            onChange={(e) => handleFieldChange(JSON.parse(e.target.value))}
          />
        );
      } else if (value instanceof Date) {
        return (
          <DatePicker
            showTime
            value={dayjs(value)}
            disabled={!canWrite}
            onChange={(date) => handleFieldChange(date ? Timestamp.fromDate(date.toDate()) : null)}
          />
        );
      } else if (value instanceof Timestamp) {
        return (
          <DatePicker
            showTime
            value={dayjs(value.toDate())}
            disabled={!canWrite}
            onChange={(date) => handleFieldChange(date ? Timestamp.fromDate(date.toDate()) : null)}
          />
        );
      } else {
        return (
          <Input.TextArea
            value={JSON.stringify(value)}
            readOnly={!canWrite}
            onChange={(e) => handleFieldChange(JSON.parse(e.target.value))}
          />
        );
      }
    default:
      console.error(`Unable to render field ${fieldId}. Unknown value type:`, value);
      return <Typography.Text type="danger">Error: Unable to render field</Typography.Text>;
  }
};