import { httpsCallable } from 'firebase/functions';
import Chat from 'openai';

import { functions } from './firebaseFunctions';

interface CallLanguageModelRequest {
    system?: string;
    prompt: string;
}
interface CallLanguageModelResponse {
    completion: Chat.ChatCompletion;
    pointsUsed: number;
}
interface CallAgentRequest {
    system?: string;
    prompt: string;
}
interface CallAgentResponse {
    result: { output: string };
    pointsUsed: number;
}

export const callAgentFB = async (requestData: CallAgentRequest): Promise<CallAgentResponse> => {
    const callAgent = httpsCallable<CallAgentRequest, CallAgentResponse>(functions, 'callAgent');

    const constantRequestData = {
        system: 'You are an Architect. Write plain text.',
        model: 'gpt-4-1106-preview',
        ...requestData,
    };

    try {
        console.log('##MODEL REQUEST: ', constantRequestData?.prompt, constantRequestData);
        const result = await callAgent(constantRequestData);
        console.log('##MODEL RESULT DATA: ', result.data);
        return result.data;
    } catch (error) {
        console.error(`Error calling callCompletion function: ${error}`);
        throw new Error(`Failed to call language model: ${error}`);
    }
};

export const callLanguageModel = async (requestData: CallLanguageModelRequest): Promise<CallLanguageModelResponse> => {

    const callCompletion = httpsCallable<CallLanguageModelRequest, CallLanguageModelResponse>(functions, 'callCompletion');

    const constantRequestData = {
        system: 'You are an Architect. Write plain text.',
        model: 'gpt-4-1106-preview',
        temperature: 0.95,
        topp: 1,
        ...requestData,
    };

    try {
        console.log('##MODEL REQUEST: ', constantRequestData?.prompt, constantRequestData);
        const result = await callCompletion(constantRequestData);
        console.log('##MODEL RESULT DATA: ', result?.data?.completion?.choices[0]?.message?.content, result);
        return result.data;
    } catch (error) {
        console.error(`Error calling callCompletion function: ${error}`);
        throw new Error(`Failed to call language model: ${error}`);
    }
};
