import React from 'react';
import {
    Input,
    Select,
    DatePicker,
    InputNumber,
    Switch,
    Typography,
    TimePicker,
    Upload,
    Image,
    Button,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import TableEdit from '../atoms/TableEdit';
import ListEdit from '../atoms/ListEdit';
import { Field, UIField, TableDataType, fieldType, QosValues } from '../../types/System.types';
import { Timestamp } from 'firebase/firestore';
import { QosComponent } from '../orgs/ClwQos';
import { renderByValueType } from './renderByValueType';

type TimeRange = {
    start: Timestamp;
    end: Timestamp;
};

interface RenderFieldProps {
    field: Field | undefined;
    uiField: UIField;
    isReadOnly: boolean;
    handleFieldChange: (value: any) => void;
}

const getDefaultValue = (type: string): TableDataType => {
    switch (type) {
        case 'number': return 0;
        case 'boolean': return false;
        case 'timestamp': return Timestamp.now();
        default: return '';
    }
};

export const renderField: React.FC<RenderFieldProps> = ({ field, uiField, isReadOnly, handleFieldChange }) => {
    switch (fieldType[uiField.fieldTypeId]?.fieldDataTypeId) {
        case 'text':
        case 'email':
        case 'phone':
        case 'url':
        case 'ipAddress':
            return (
                <Input
                    variant='filled'
                    value={field?.value as string}
                    readOnly={isReadOnly}
                    onChange={(e) => handleFieldChange(e.target.value)}
                    {...uiField.props}
                />
            );
        case 'longText':
        case 'richText':
        case 'json':
        case 'xml':
        case 'html':
            return (
                <Input.TextArea
                    autoSize
                    variant='filled'
                    value={field?.value as string}
                    readOnly={isReadOnly}
                    onChange={(e) => handleFieldChange(e.target.value)}
                    {...uiField.props}
                />
            );
        case 'singleSelect':
            return (
                <Select
                    variant='filled'
                    value={field?.value as string}
                    disabled={isReadOnly}
                    onChange={handleFieldChange}
                    {...uiField.props}
                />
            );
        case 'multiSelect':
            return (
                <Select
                    variant='filled'
                    mode="multiple"
                    value={field?.value as string[]}
                    disabled={isReadOnly}
                    onChange={handleFieldChange}
                    {...uiField.props}
                />
            );
        case 'date':
            return (
                <DatePicker
                    variant='filled'
                    value={field?.value ? dayjs(field.value.toDate()) : null}
                    disabled={isReadOnly}
                    onChange={(date) => handleFieldChange(date ? Timestamp.fromDate(date.toDate()) : null)}
                />
            );
        case 'dateTime':
            return (
                <DatePicker
                    variant='filled'
                    showTime
                    value={field?.value ? dayjs(field.value.toDate()) : null}
                    disabled={isReadOnly}
                    onChange={(date) => handleFieldChange(date ? Timestamp.fromDate(date.toDate()) : null)}
                />
            );
        case 'time':
            return (
                <TimePicker
                    variant='filled'
                    value={field?.value ? dayjs(field.value.toDate()) : null}
                    disabled={isReadOnly}
                    onChange={(time) => handleFieldChange(time ? Timestamp.fromDate(time.toDate()) : null)}
                    {...uiField.props}
                />
            );
        case 'currency':
        case 'quantity':
        case 'percentage':
        case 'number':
            return (
                <InputNumber
                    variant='filled'
                    value={field?.value as number}
                    readOnly={isReadOnly}
                    onChange={handleFieldChange}
                    {...uiField.props}
                />
            );
        case 'yesNo':
            return (
                <Switch
                    checked={field?.value as boolean}
                    disabled={isReadOnly}
                    onChange={handleFieldChange}
                />
            );
        case 'timeRange':
        case 'dateRange':
        case 'dateTimeRange':
            const range = field?.value as TimeRange;
            return (
                <DatePicker.RangePicker
                    variant='filled'
                    showTime={uiField.fieldTypeId === 'timeRange' || uiField.fieldTypeId === 'dateTimeRange'}
                    value={range ? [dayjs(range.start.toDate()), dayjs(range.end.toDate())] : null}
                    disabled={isReadOnly}
                    onChange={(dates) => {
                        if (dates && dates[0] && dates[1]) {
                            handleFieldChange({
                                start: Timestamp.fromDate(dates[0].toDate()),
                                end: Timestamp.fromDate(dates[1].toDate())
                            });
                        } else {
                            handleFieldChange(null);
                        }
                    }}
                />
            );
        case 'address':
            return (
                <Input.TextArea
                    variant='filled'
                    value={field?.value as string}
                    readOnly={isReadOnly}
                    onChange={(e) => handleFieldChange(e.target.value)}
                />
            );
        case 'table':
            return (
                <TableEdit
                    dataSource={field?.value as any[]}
                    columns={uiField.columns || []}
                    onCellChange={(rowIndex, columnKey, value) => {
                        const updatedData = [...(field?.value as any[] || [])];
                        updatedData[rowIndex] = {
                            ...updatedData[rowIndex],
                            [columnKey]: value
                        };
                        handleFieldChange(updatedData);
                    }}
                    readOnly={isReadOnly}
                    onAddRow={() => {
                        const updatedData = [...(field?.value as any[] || [])];
                        const newRow = (uiField.columns || []).reduce<Record<string, TableDataType>>((acc, col) => ({
                            ...acc,
                            [col.dataIndex]: getDefaultValue(col?.celltype as string || 'string')
                        }), {});
                        updatedData.push(newRow);
                        handleFieldChange(updatedData);
                    }}
                    onRemoveRow={(index: number) => {
                        const updatedData = [...(field?.value as any[] || [])];
                        updatedData.splice(index, 1);
                        handleFieldChange(updatedData);
                    }}
                />
            );
        case 'list':
            return (
                <ListEdit
                    dataSource={field?.value as TableDataType[]}
                    onCellChange={(index, _, value) => {
                        const updatedData = [...(field?.value as TableDataType[] || [])];
                        updatedData[index] = value as TableDataType;
                        handleFieldChange(updatedData);
                    }}
                    readOnly={isReadOnly}
                    onAddRow={() => {
                        const updatedData = [...(field?.value as TableDataType[] || [])];
                        updatedData.push(getDefaultValue('string'));
                        handleFieldChange(updatedData);
                    }}
                    onRemoveRow={(index: number) => {
                        const updatedData = [...(field?.value as TableDataType[] || [])];
                        updatedData.splice(index, 1);
                        handleFieldChange(updatedData);
                    }}
                />
            );
        case 'file':
            return (
                <Upload disabled={isReadOnly}>
                    {field?.value ? <a>{field?.value}</a> : <Button icon={<UploadOutlined />}>Click to Upload</Button>}
                </Upload>
            );
        case 'image':
            return (
                <Image
                    src={field?.value as string}
                    alt="Field image"
                />
            );
        case 'video':
        case 'link':
            return (
                <Button
                    href={field?.value as string}
                    type='link'
                >
                    {field?.value}
                </Button>
            );
        case 'password':
            return (
                <Input.Password
                    variant='filled'
                    value={field?.value as string}
                    readOnly={isReadOnly}
                    onChange={(e) => handleFieldChange(e.target.value)}
                />
            );
        case 'qos':
            return (
                <QosComponent
                    qos={field?.value as QosValues}
                    onChange={handleFieldChange}
                    disabled={isReadOnly}
                />
            );
        case 'null':
            return (
                <Typography.Text>
                    Null
                </Typography.Text>
            );
        default:
            return renderByValueType(field?.value, uiField.fieldTypeId, !isReadOnly, handleFieldChange);
    }
};