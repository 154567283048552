import { getAuth } from 'firebase/auth';

import { cloudRunURLs } from '../config';

interface LanguageModelRequest {
    system: string;
    prompt: string;
    model?: string;
    maxtokens?: number;
    temperature?: number;
    topp?: number;
}

export const callRunLanguageModel = async (
    requestData: LanguageModelRequest,
    onData: (chunk: string) => void,
    onError: (error: Error) => void,
    onStreamClose: () => void
) => {
    const url = cloudRunURLs.runLanguageModel as string;
    // const url = "http://localhost:8080/runlanguagemodel"; // ### TEST

    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
        console.error('User not logged in');
        throw new Error('User not logged in');
    }

    try {
        const idToken = await user.getIdToken();

        const bodyData = {
            data: { 
                ...requestData,
            },
            idToken: idToken,
        };

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(bodyData),
        });

        if (!response.body) {
            throw new Error('Failed to get readable stream');
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        const read = async () => {
            try {
                const { done, value } = await reader.read();
                if (done) {
                    onStreamClose();
                    return;
                }

                const chunk = decoder.decode(value, { stream: true });
                console.log(chunk);
                onData(chunk);

                read();
            } catch (error) {
                console.error('Stream reading failed:', error);
                onError(new Error('Stream reading failed' + error));
            }
        };

        read();
    } catch (error) {
        console.error('Error calling the language model service:', error);
        throw error;
    }
};
