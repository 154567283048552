import React, { FC, useMemo } from 'react';
import { UIElement, EntityRecord, FormVersion, UIElementType } from '../../types/System.types';
import FieldUI from './FieldUI';
import ContainerUI from './ContainerUI';
import EntityUI from './EntityUI';
import { EntityProvider } from '../../providers/EntityProvider';

interface LayoutUIProps {
  uiElement: UIElement;
  itemData: EntityRecord | FormVersion;
}

const LayoutUI: FC<LayoutUIProps> = React.memo(({
  uiElement,
  itemData,
}) => {
  const content = useMemo(() => {
    switch (uiElement.uiElementType) {
      case UIElementType.Field:
        if ('fields' in itemData) {
          return <FieldUI uiField={uiElement} />;
        }
        return null;
      case UIElementType.EntityReference:
        if (uiElement.entityId && 'entityReferences' in itemData) {
          return (
            <EntityProvider
              initialEntityId={uiElement.entityId}
              initialRecordId={itemData.entityReferences[uiElement.entityId]}
            >
              <EntityUI />
            </EntityProvider>
          );
        }
        return null;
      case UIElementType.Grid:
      case UIElementType.Row:
      case UIElementType.Column:
        return <ContainerUI
          container={uiElement}
          itemData={itemData}
        />;
      default:
        console.warn(`Unknown UI element type: ${(uiElement as any).uiElementType}`);
        return null;
    }
  }, [uiElement, itemData]);

  return content;
});

LayoutUI.displayName = 'LayoutUI';

export default LayoutUI;