import React, { FC } from 'react';
import { Dropdown, Button } from 'antd';
import type { MenuProps } from 'antd';
import { MoreOutlined, DeleteOutlined } from '@ant-design/icons';

interface RowActionsProps {
  onRemoveRow: () => void;
  disabled?: boolean;
}

const RowActions: FC<RowActionsProps> = ({ onRemoveRow, disabled = false }) => {
  const items: MenuProps['items'] = [
    {
      key: 'delete',
      icon: <DeleteOutlined />,
      label: 'Delete',
      onClick: onRemoveRow,
      disabled: disabled,
    },
  ];

  return (
    <Dropdown 
      menu={{ items }} 
      trigger={['click']} 
      placement='topRight'
    >
      <Button 
        icon={<MoreOutlined />} 
        type="text"
        disabled={disabled}
      />
    </Dropdown>
  );
};

export default RowActions;