import { useState, useContext, useEffect } from 'react';
import { Modal, Form, Input, Button, Typography, Divider, Alert } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';

import { AIContext, ActionCardType } from '../../providers/AIProvider';
import { UserContext } from '../../providers/UserProvider';

const { TextArea } = Input;
const { Text } = Typography;

interface InputModalProps {
    card: ActionCardType;
    open: boolean;
    index: number;
    handleOk: () => void; 
    handleCancel: () => void; 
    [key: string]: any;
}

const InputModal = ({ card, index, handleOk, handleCancel, ...otherProps }: InputModalProps) => {
    const [generalInstructions, setGeneralInstructions] = useState('');
    const [document, setDocument] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const {
        callModel,
    } = useContext(AIContext);
    const { 
        settings
    } = useContext(UserContext);

    useEffect(() => {
        setGeneralInstructions(card.prompt);
    }, [card]);

    const onFinish = async (values: any) => {
        setIsLoading(true);

        const p = generalInstructions + '\n\nContract:\n' + document;

        try {
            await callModel(settings?.role || 'Architect', true, p);
        } catch (e) {
            if (e instanceof Error) {
                setError(e.message);
            }
            console.error(e);
        }
        finally {
            setIsLoading(false);
            handleOk();
        }
    };

    return (
        <Modal
            title={card.title}
            onCancel={handleCancel}
            footer={[
                <Button 
                    key="submit" 
                    type="primary" 
                    onClick={onFinish}
                    loading={isLoading}
                    icon={<DoubleRightOutlined />}
                >
                    SUBMIT
                </Button>,
            ]}
            width="calc(100vw - 20px)"
            style={{
                maxWidth: '980px',
            }}
            {...otherProps}
        >
            <Text>
                <small>{card.content}</small>
            </Text>
            <Divider />
            <Form 
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item label={"Instructions" + index}>
                    <TextArea
                        id="inputInstructions"
                        autoSize={{ maxRows: 2 }}
                        value={generalInstructions}
                        onChange={(e) => setGeneralInstructions(e.target.value)}
                        placeholder="General instructions"
                    />
                </Form.Item>
                <Form.Item label="Contract">
                    <TextArea
                        id={"inputContract" + index}
                        value={document}
                        onChange={(e) => setDocument(e.target.value)}
                        placeholder="Paste contract here..."
                        autoSize={{ minRows: 4, maxRows: 10 }}
                    />
                </Form.Item>
            </Form>
            {error && (
                <Alert
                    message={`Error: ${error}`}
                    type="error"
                    showIcon
                    closable
                    onClose={() => setError(null)}
                    style={{ marginTop: 15 }}
                />
            )}
        </Modal>
    );
};

export default InputModal;