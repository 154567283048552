import React, { FC, useCallback, useContext } from 'react';
import { Typography, Tooltip, Alert } from 'antd';
import { Timestamp } from 'firebase/firestore';
import { UserContext } from '../../providers/UserProvider';
import { UIField, AgentType, fieldType } from '../../types/System.types';
import { EntityContext } from '../../providers/EntityProvider';
import FlexCol from '../atoms/FlexCol';
import { renderField } from './renderField';
import { FormContext } from '../../providers/FormProvider';

interface FieldUIProps {
  uiField: UIField;
}

const FieldUI: FC<FieldUIProps> = React.memo(({ 
  uiField
}) => {
  const { user } = useContext(UserContext);
  const { 
    insideFormContext, 
    formVersionAccess, 
    selectedFormVersion 
  } = useContext(FormContext);
  const { 
    insideEntityContext, 
    readOnly: entityReadOnly, 
    debouncedSetRecord,
    selectedEntityRecord 
  } = useContext(EntityContext);

  const handleFieldChange = useCallback((value: any) => {
    const updateObject = {
      fields: { 
        [uiField.fieldId]: { 
          value: value,
          fieldTypeId: uiField?.fieldTypeId || 'text',
          meta: {
            lastModified: Timestamp.now(),
            userId: user?.uid,
            agentType: AgentType.User,
          }
        } 
      } 
    };

    if (insideEntityContext) {
      debouncedSetRecord(updateObject);
    } else if (insideFormContext && formVersionAccess?.canWrite) {
      selectedFormVersion.debouncedSet(updateObject);
    }
  }, [insideEntityContext, debouncedSetRecord, insideFormContext, formVersionAccess, selectedFormVersion, uiField.fieldId, uiField.fieldTypeId, user?.uid]); // Updated dependency array
  
  const isReadOnly = insideEntityContext 
    ? entityReadOnly 
    : !formVersionAccess?.canWrite;

  const ft = fieldType[uiField.fieldTypeId];
  const label = ft?.description?.shortLabel || uiField.fieldId;
  const description = ft?.description?.longDescription || 
    ft?.description?.shortDescription || 
    ft?.description?.longLabel || 
    ft?.description?.shortLabel;

  const fieldData = insideEntityContext 
    ? selectedEntityRecord?.fields[uiField.fieldId]
    : selectedFormVersion?.data?.fields?.[uiField.fieldId];

  if (!insideEntityContext && !insideFormContext) {
    return <Alert message="Error: Neither entity nor form context is available." type="error" />;
  }

  return (
    <FlexCol
      gap={0}
      {...uiField.props}
    >
      <Tooltip title={description}>
        <Typography.Text>
          {label}
        </Typography.Text>
      </Tooltip>
      {renderField({ field: fieldData, uiField, isReadOnly, handleFieldChange })}
    </FlexCol>
  );
});

FieldUI.displayName = 'FieldUI';

export default FieldUI;