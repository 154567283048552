import React, { FC, ReactNode } from 'react';
import { Grid } from 'antd';
import FlexBox, { FlexBoxProps } from './FlexBox';

interface FlexContentProps extends FlexBoxProps {
    children: ReactNode;
    style?: React.CSSProperties;
}

const FlexContent: FC<FlexContentProps> = ({ children, style, ...props }) => {
    const { useBreakpoint } = Grid;
    const { sm } = useBreakpoint();

    return (
        <FlexBox
            column
            gap={10}
            alignStart
            justifyStart
            wrap
            style={{
                padding: sm ? 20 : 10,
                width: '100%',
                ...style,
            }}
            {...props}
        >
            {children}
        </FlexBox>
    );
};

export default FlexContent;