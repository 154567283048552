import { useState, useEffect } from 'react';
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  getIdToken,
  GoogleAuthProvider,
  signInWithRedirect,
  Auth,
  User
} from 'firebase/auth';
import { initializeApp, FirebaseApp } from 'firebase/app';

import { firebaseConfig } from '../config';

interface UseFirebaseAuth {
  user: User | null;
  loading: boolean;
  login: (email: string, password: string) => Promise<void>;
  signup: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  fetchIdToken: () => Promise<string | null>;
  signInWithGoogle: () => Promise<void>;
}

export default function useFirebaseAuth(): UseFirebaseAuth {
  const app: FirebaseApp = initializeApp(firebaseConfig);
  const auth: Auth = getAuth(app);

  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  const login = async (email: string, password: string): Promise<void> => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      throw error;
    }
  };

  const signup = async (email: string, password: string): Promise<void> => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (error) {
      throw error;
    }
  };

  const signInWithGoogle = async (): Promise<void> => {
    const provider = new GoogleAuthProvider();
    await signInWithRedirect(auth, provider);
  };

  const logout = async (): Promise<void> => {
    try {
      await signOut(auth);
    } catch (error) {
      throw error;
    }
  };

  const fetchIdToken = async (): Promise<string | null> => {
    try {
      if (user) {
        const idToken = await getIdToken(user);
        return idToken;
      }
      return null;
    } catch (error) {
      throw error;
    }
  };

  return { user, loading, login, signup, logout, signInWithGoogle, fetchIdToken };
}