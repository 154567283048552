import React, { useContext, useState, ReactNode } from 'react';
import Navb from '../components/Navb';
import {
  Select,
  Tabs,
  theme,
  Typography,
  Descriptions,
  Table,
  Button,
  message,
  Tooltip,
} from 'antd';
import FlexBox from '../components/atoms/FlexBox';
import FlexPage from '../components/atoms/FlexPage';
import {
  AnalyticsContext,
  AnalyticsProvider,
} from '../providers/AnalyticsProvider';
import { getFunctions, httpsCallable } from 'firebase/functions';
import FlexContent from '../components/atoms/FlexContent';
import { Timestamp } from 'firebase/firestore';


function formatTimestamp(timestamp: Timestamp): string {
  return timestamp.toDate().toISOString().split('T')[0];
}


const AnalyticsPageContent: React.FC = () => {
  const { token } = theme.useToken();
  const {
    metrics,
    selectedMetric,
    selectMetric,
    metricGroupedBy,
    selectedGroupedMetric,
    selectGroupedMetric,
    metricRecords,
    loading,
    error,
  } = useContext(AnalyticsContext);

  const [recalculationLoading, setRecalculationLoading] = useState(false);

  const renderSelector = ({
    title,
    options,
    value,
    onChange,
    loading
  }: {
    title: string;
    options: any[];
    value: string | undefined;
    onChange: (value: string | undefined) => void;
    loading: boolean;
  }) => (
    <FlexBox
      column
      alignStart
      justifyStart
      noGrow
      gap={0}
      style={{
        marginBottom: '20px',
        width: '100%',
      }}
    >
      <Typography.Text>
        {title}
      </Typography.Text>
      <Select
        style={{ width: '100%' }}
        placeholder={`Select ${title}`}
        loading={loading}
        value={value}
        onChange={onChange}
      >
        {options.map((option) => (
          <Select.Option 
            key={option.docId} 
            value={option.docId}
          >
            <Tooltip title={option.description.longLabel} placement="right">
              <span>{option.description.shortLabel}</span>
            </Tooltip>
          </Select.Option>
        ))}
      </Select>
    </FlexBox>
  );

  const handleFullRecalculation = async () => {
    setRecalculationLoading(true);
    const functions = getFunctions();
    const runFullMetricRecalculation = httpsCallable(functions, 'run_full_metric_recalculation');

    try {
      const result = await runFullMetricRecalculation();
      message.success('Full metric recalculation completed successfully');
    } catch (error) {
      console.error('Error during full metric recalculation:', error);
      message.error('Failed to complete full metric recalculation');
    } finally {
      setRecalculationLoading(false);
    }
  };

  const renderMetricDefinition = () => {
    if (!selectedMetric) {
      return <div>Please select a metric to view its definition.</div>;
    }

    const formatValue = (value: any, unit?: string): string => {
      if (value === undefined || value === null) return '';
      if (value instanceof Timestamp) return formatTimestamp(value);
      if (typeof value === 'object') return JSON.stringify(value);
      if (typeof value === 'number') {
        switch (unit) {
          case 'percentage':
            return `${(value * 100).toFixed(2)}%`;
          case 'currency':
            return `$${Math.round(value).toLocaleString()}`;
          default:
            return value.toString();
        }
      }
      return value.toString();
    };

    const formatLabel = (label: string): string => {
      // Split the label by dots
      const parts = label.split('.');
      
      // Process each part
      const formattedParts = parts.map(part => {
        // Split camelCase and capitalize each word
        return part.split(/(?=[A-Z])/)
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ');
      });
      
      // Join the parts with a colon
      return formattedParts.join(': ');
    };

    const renderDescriptionItems = (obj: any, prefix: string = '', depth: number = 0): ReactNode[] => {
      if (depth > 5) return []; // Prevent infinite recursion
      
      const excludedFields = ['docId', 'userRoles', 'userGroup', 'meta'];
      
      return Object.entries(obj).flatMap(([key, value]): ReactNode[] => {
        if (excludedFields.includes(key)) return []; // Skip excluded fields
        
        const label = prefix ? `${prefix}.${key}` : key;
        const formattedLabel = formatLabel(label);
        
        // Special handling for groupBy
        if (key === 'groupBy' && Array.isArray(value)) {
          return [
            <Descriptions.Item key={label} label={formattedLabel}>
              {value.map((group) => group.id).join(', ')}
            </Descriptions.Item>
          ];
        }
        
        if (typeof value === 'object' && value !== null && !(value instanceof Timestamp)) {
          if (Array.isArray(value)) {
            return [
              <Descriptions.Item key={label} label={formattedLabel}>
                {value.join(', ')}
              </Descriptions.Item>
            ];
          } else {
            return renderDescriptionItems(value, label, depth + 1);
          }
        } else {
          return [
            <Descriptions.Item key={label} label={formattedLabel}>
              {formatValue(value, obj.metricUnit)}
            </Descriptions.Item>
          ];
        }
      });
    };

    return (
      <>
        <Button
          type="primary"
          danger
          size="large"
          loading={recalculationLoading}
          onClick={handleFullRecalculation}
          disabled={true}
          style={{
            marginBottom: '20px',
            width: '100%',
            maxWidth: '500px',
            height: '50px',
            fontSize: '18px',
          }}
        >
          Run Full Metric Recalculation
        </Button>
        <Descriptions bordered column={1} size="small">
          {renderDescriptionItems(selectedMetric)}
        </Descriptions>
      </>
    );
  };

  const renderMetricRecords = () => {
    if (!selectedMetric || !selectedGroupedMetric) {
      return <div>Please select a metric and a group to view records.</div>;
    }

    const formatMetricValue = (value: number) => {
      switch (selectedMetric.additionalSettings?.metricUnit) {
        case 'percentage':
          return `${(value * 100).toFixed(2)}%`;
        case 'currency':
          // TODO: This should be handled elsewhere in our types, possibly in a utility function or constants file
          return `$${Math.round(value).toLocaleString()}`;
        default:
          return value.toString();
      }
    };

    const columns = [
      {
        title: selectedMetric.timeParams.timePeriods[0].charAt(0).toUpperCase() + selectedMetric.timeParams.timePeriods[0].slice(1),
        dataIndex: 'metricTimeString',
        key: 'metricTimeString',
        width: 125
      },
      {
        title: selectedMetric.description.shortLabel,
        dataIndex: 'metricValue',
        key: 'metricValue',
        render: (value: number) => formatMetricValue(value),

      },
      {
        title: (
          <FlexBox wrap>
            {`Number of ${selectedMetric.source.parentFormType}s`}
          </FlexBox>
        ),
        dataIndex: 'metricCount',
        key: 'metricCount',
        render: (value: number) => (
          <FlexBox wrap>
            {value}
          </FlexBox>
        ),
      },
    ];

    return (
      <Table
        dataSource={metricRecords}
        columns={columns}
        rowKey="docId"
        loading={loading.metricRecords}
      />
    );
  };

  const tabItems = [

    {
      key: 'tab1',
      label: 'Metric Records',
      children: renderMetricRecords(),
    },
    {
      key: 'tab2',
      label: 'Metric Definition',
      children: renderMetricDefinition(),
    },
  ];

  return (
    <FlexPage>
      <Navb />
      <FlexContent column={false} gap={20}>
        <FlexBox
          column
          wrap
          alignStart
          justifyStart
          stretch
          gap={0}
          style={{
            width: 300,
            maxWidth: 300,
            paddingRight: 20,
            borderRight: '1px solid ' + token.colorBorder,
          }}
        >
          {renderSelector({
            title: 'Metric',
            options: metrics,
            value: selectedMetric?.docId,
            onChange: selectMetric,
            loading: loading.metrics
          })}
          {selectedMetric &&
            renderSelector({
              title: 'Group By',
              options: metricGroupedBy,
              value: selectedGroupedMetric?.docId,
              onChange: (value) => selectGroupedMetric(value as string | undefined),
              loading: loading.groupedMetrics
            })}
        </FlexBox>
        <FlexBox
          column
          stretch
          style={{
            flex: 1,
          }}
        >
          <Tabs
            defaultActiveKey="tab1"
            items={tabItems}
            style={{
              display: 'flex',
              width: '100%',
            }}
          />
        </FlexBox>
      </FlexContent>
    </FlexPage>
  );
};

const AnalyticsPage: React.FC = () => (
  <AnalyticsProvider>
    <AnalyticsPageContent />
  </AnalyticsProvider>
);

export default AnalyticsPage;
