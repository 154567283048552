import { FC } from 'react';
import { Form } from '../types/System.types';
import { useTable } from '../use/useTable';

import FlexPage from '../components/atoms/FlexPage';
import FlexContent from '../components/atoms/FlexContent';
import Navb from '../components/Navb';
import FormsTable from '../components/artifacts/FormsTable';
import FormsTableControls from '../components/artifacts/FormsTableControls';

const FormsPage: FC = () => {
    const tableProps = useTable<Form>({
        path: 'forms',
        initialPageSize: 10,
        initialOrderBy: [{ field: 'meta.created', direction: 'desc' }],
    });

    return (
        <FlexPage>
            <Navb />
            <FlexContent>
                <FormsTableControls tableProps={tableProps} />
                <FormsTable tableProps={tableProps} />
            </FlexContent>
        </FlexPage>
    );
};

export default FormsPage;

