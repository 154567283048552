import React, { useState, useEffect } from 'react';
import { Input, Tooltip, Button, Space, Typography } from 'antd';
import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import FlexBox from '../atoms/FlexBox';
import { validateQosValues } from '../../validations/orgs/clwQosValidation';
import { QosValues } from '../../types/CLW.Config.types';

const { Text } = Typography;

interface QosComponentProps {
  qos: QosValues;
  onChange: (qos: QosValues) => void;
  disabled?: boolean;
}

const DEFAULT_QOS: QosValues = {
  totalLines: 0,
  clwRepresentedLines: 0,
  clwSolelyListedLines: 0,
  clwQuotedLines: 0,
};

export const QosComponent: React.FC<QosComponentProps> = ({
  qos = DEFAULT_QOS,
  onChange,
  disabled = false,
}) => {
  const [inputStatus, setInputStatus] = useState<{
    [key in keyof QosValues]?: '' | 'error' | 'warning';
  }>({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [warningMessage, setWarningMessage] = useState<string | null>(null);

  const updateValidationState = (
    validationResult: ReturnType<typeof validateQosValues>,
    field?: keyof QosValues
  ) => {
    const { isValid, type, message } = validationResult;
    setInputStatus((prevStatus) => ({
      ...prevStatus,
      [field || 'totalLines']: type === 'warning' ? 'warning' : '',
    }));
    setErrorMessage(type === 'error' && message ? message : null);
    setWarningMessage(type === 'warning' && message ? message : null);
    return isValid;
  };

  useEffect(() => {
    updateValidationState(validateQosValues(qos));
  }, [qos]);

  const handleInputChange =
    (field: keyof QosValues) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = parseInt(e.target.value, 10) || 0;
      const newQos: QosValues =
        field === 'totalLines'
          ? { ...DEFAULT_QOS, totalLines: inputValue }
          : { ...qos, [field]: inputValue };

      if (updateValidationState(validateQosValues(newQos), field)) {
        onChange(newQos);
      }
    };

  const handleReset = () => {
    onChange(DEFAULT_QOS);
    setInputStatus({} as Record<keyof QosValues, '' | 'error' | 'warning'>);
    setErrorMessage(null);
    setWarningMessage(null);
  };

  const renderInput = (field: keyof QosValues, title: string) => (
    <Tooltip title={title}>
      <Input
        style={{ 
          width: 75, 
          textAlign: 'center'
        }}
        variant='filled'
        size='large'
        value={qos[field]}
        onChange={handleInputChange(field)}
        onBlur={() => updateValidationState(validateQosValues(qos), field)}
        disabled={disabled}
        status={inputStatus[field]}
        prefix={
          field === 'totalLines' && inputStatus[field] === 'warning' ? (
            <ExclamationCircleOutlined />
          ) : null
        }
      />
    </Tooltip>
  );

  return (
    <FlexBox 
      column 
      justifyStart 
      alignStart 
      gap={0}
    >
      <FlexBox gap={2}>
        {renderInput('totalLines', 'Total Lines')}
        {renderInput('clwRepresentedLines', 'Represented Lines')}
        {renderInput('clwSolelyListedLines', 'Solely Listed')}
        {renderInput('clwQuotedLines', 'Quoting')}
        <Button
          htmlType="button"
          onClick={handleReset}
          icon={<CloseCircleOutlined />}
          type="link"
          disabled={disabled}
        />
      </FlexBox>
      <Text type={errorMessage ? 'danger' : 'warning'}>
        <small>
          <b>
            {errorMessage || warningMessage || <span>&nbsp;</span>}
          </b>
        </small>
      </Text>
    </FlexBox>
  );
};
