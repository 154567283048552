import { 
    useCallback,
    useContext
} from 'react';
import {
    collection,
    addDoc,
} from 'firebase/firestore';

import { DataContext } from '../../providers/DataProvider';
import { DocDataWithId, DocId } from '../../types/System.types';

/**
 * Interface for data add operations
 * @property {function} add - Function to add data to Firestore
 */
export interface UseAdd {
    /**
     * Adds data to Firestore
     * @param path - The Firestore collection path.
     * @param data - The data to add.
     * @returns The document ID.
     */
    add: <T extends DocDataWithId = DocDataWithId>(
        path: string, 
        data: Omit<T, 'docId'>
    ) => Promise<DocId>;
}

/**
 * Hook for managing Firestore data add operations
 * @param firestore - Firestore instance
 * @returns Object with add function
 */
export const useAdd = (): UseAdd => {
    const { firestore } = useContext(DataContext);

    const add = useCallback(async <T extends DocDataWithId = DocDataWithId>(
        path: string, 
        data: Omit<T, 'docId'>
    ): Promise<DocId> => {
        const collectionRef = collection(firestore, path);
        try {
            const docRef = await addDoc(collectionRef, data);
            return docRef.id;
        } catch (error) {
            console.error('Error adding document:', error);
            throw error;
        }
    }, [firestore]);

    return { add };
};