import { FC } from 'react';
import { 
    Tag,
    Tooltip,
    Grid
} from 'antd';
import Icon from './Icon';

interface DocumentAccessProps {
    canRead: boolean;
    canWrite: boolean;
}

const { useBreakpoint } = Grid;

/**
 * DocumentAccess component displays a tag indicating the user's access level to a document.
 * @param {DocumentAccessProps} props - The component props.
 * @param {boolean} props.canRead - Indicates if the user has read access.
 * @param {boolean} props.canWrite - Indicates if the user has write access.
 * @returns {JSX.Element} A tag displaying the access level.
 */
const DocumentAccess: FC<DocumentAccessProps> = ({ canRead, canWrite }) => {
    /**
     * Determines the appropriate access tag based on the user's permissions.
     * @returns {JSX.Element} The access tag with appropriate color, icon, and tooltip.
     */
    const screens = useBreakpoint();
    const getAccessTag = () => {
        if (canRead && canWrite) {
            return (
                <Tooltip title="You can view and edit this document">
                    <Tag>
                        <Icon.Edit  />
                        {!screens.xs && " Full Access"}
                    </Tag>
                </Tooltip>
            );
        } else if (canRead) {
            return (
                <Tooltip title="You can only view this document">
                    <Tag>
                        <Icon.Eye />
                        {!screens.xs && " Read Only"}
                    </Tag>
                </Tooltip>
            );
        } else {
            return (
                <Tooltip title="You don't have access to this document">
                    <Tag>
                        <Icon.Lock />
                        {!screens.xs && " No Access"}
                    </Tag>
                </Tooltip>
            );
        }
    };

    return getAccessTag();
};

export default DocumentAccess;