import React, { FC, useMemo } from 'react';
import { Row, Col } from 'antd';
import { UIContainer, EntityRecord, FormVersion, UIElementType } from '../../types/System.types';
import FlexBox from '../atoms/FlexBox';
import FlexCol from '../atoms/FlexCol';
import LayoutUI from './LayoutUI';

interface ContainerUIProps {
  container: UIContainer;
  itemData: EntityRecord | FormVersion;
}

const ContainerUI: FC<ContainerUIProps> = React.memo(({ 
  container, 
  itemData, 
}) => {
  const renderChildren = useMemo(() => {
    return container.children.map((child, index) => (
      <LayoutUI
        key={`${container.uiElementType}-${index}`}
        uiElement={child}
        itemData={itemData}
      />
    ));
  }, [container.children, container.uiElementType, itemData]);

  const content = useMemo(() => {
    switch (container.uiElementType) {
      case UIElementType.Grid:
        return (
          <Row gutter={container.props?.gap || 10}>
            {container.children.map((child, index) => (
              <Col key={`grid-${index}`} span={24 / container.children.length}>
                <LayoutUI
                  uiElement={child}
                  itemData={itemData}
                />
              </Col>
            ))}
          </Row>
        );
      case UIElementType.Row:
        return (
          <FlexBox
            gap={container.props?.gap || 10}
            wrap
            justifyStart
            alignStart
            stretch
            noGrow
            {...container.props}
          >
            {renderChildren}
          </FlexBox>
        );
      case UIElementType.Column:
        return (
          <FlexCol
            gap={container.props?.gap}
            {...container.props}
          >
            {renderChildren}
          </FlexCol>
        );
      default:
        console.warn(`Unknown container type: ${(container as any).uiElementType}`);
        return null;
    }
  }, [container, itemData, renderChildren]);

  return content;
});

ContainerUI.displayName = 'ContainerUI';

export default ContainerUI;