import { FC, useMemo } from 'react';
import {
    Table,
    Button
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { TableProps, ColumnType } from 'antd/es/table';

import CellEdit from './CellEdit';
import RowActions from './RowActions';

import { TableDataType, tableTypeOf } from '../../types/System.types';

export interface TableEditColumn extends ColumnType<any> {
    celltype?: TableDataType;
}

interface TableEditProps extends Omit<TableProps<any>, 'columns'> {
    onCellChange: (rowIndex: number, columnKey: string, value: TableDataType) => void;
    columns: TableEditColumn[];
    readOnly?: boolean;
    onAddRow?: () => void;
    onRemoveRow?: (index: number) => void;
}

const TableEdit: FC<TableEditProps> = ({
    dataSource,
    columns,
    onCellChange,
    readOnly,
    onAddRow,
    onRemoveRow,
    ...props
}) => {
    const editableColumns = useMemo(() => [
        ...columns?.map((col: TableEditColumn) => ({
            ...col,
            render: (text: TableDataType, record: any, index: number) => (
                <CellEdit
                    key={index}
                    value={text}
                    type={tableTypeOf(text)}
                    onChange={(value) => {
                        if (!readOnly) {
                            onCellChange(index, col.dataIndex as string, value);
                        }
                    }}
                    readOnly={readOnly}
                />
            ),
        })),
        {
            key: 'actions',
            render: (_, __, index) => (
                <RowActions
                    onRemoveRow={() => onRemoveRow?.(index)}
                    disabled={readOnly}
                />
            ),
        },
    ], [columns, onRemoveRow, readOnly, onCellChange]);

    const dataSourceWithKey = useMemo(() =>
        dataSource?.map((item, i) => ({
            ...item,
            indexToBeUsedAsKey: i
        })),
        [dataSource]
    );

    return (
        <Table
            {...props}
            size='small'
            tableLayout='auto'
            dataSource={dataSourceWithKey}
            columns={editableColumns}
            rowKey='indexToBeUsedAsKey'
            footer={() => (
                <Button
                    onClick={onAddRow}
                    type="link"
                    size='small'
                    icon={<PlusOutlined />}
                    disabled={readOnly || !onAddRow}
                >
                    Add Row
                </Button>
            )}
        />
    );
};

export default TableEdit;