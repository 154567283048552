import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeContext, ThemeContextType } from '../providers/ThemeProvider'; 
import { Typography } from 'antd';

const ErrorPage: React.FC = () => {
    const navigate = useNavigate();
    const { darkMode } = useContext<ThemeContextType>(ThemeContext); 

    const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault(); 
        navigate('/');
    };

    return (
        <div 
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} 
            className={darkMode ? 'gradient-dark' : 'gradient-light'}
        >
            <Typography.Title level={1}>
                <em>You did not see this, you are not <a href="/" onClick={onClick}>here</a>.</em>
            </Typography.Title>
        </div>
    );
};

export default ErrorPage;