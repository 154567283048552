import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { environmentType } from '../config';

export const functions = getFunctions();

// Check and use emulator if in development environment
export const checkAndUseEmulator = async () => {
    if (environmentType === 'development') {
        try {
            const response = await fetch('http://localhost:8080');
            if (response.ok) {
                connectFunctionsEmulator(functions, 'localhost', 5001);
                console.log('Connected to Firebase Functions emulator');
            }
        } catch (error) {
            console.log('Firebase Functions emulator is not running');
        }
    }
};

// Call this function at the top level of your app
checkAndUseEmulator();