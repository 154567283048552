import {
    useState,
    useEffect,
    useCallback,
    useContext
} from 'react';
import {
    doc,
    onSnapshot,
    setDoc,
    DocumentReference,
} from 'firebase/firestore';
import { debounce } from 'lodash';

import { DataContext } from '../../providers/DataProvider';
import { DocDataWithId, DocId } from '../../types/System.types';

/**
 * Result of the useBoundDoc hook.
 */
export interface UseBoundDoc<T extends DocDataWithId = DocDataWithId> {
    /** The bound document data. */
    data: T | null;
    /** Any error that occurred during binding. */
    error: Error | null;
    /** Indicates if the data is currently loading. */
    loading: boolean;
    /** Function to update the document with debounce. */
    debouncedSet: (updates: Partial<T>) => void;
}

/**
 * Hook for binding to a single Firestore document.
 * @param path - The Firestore collection path.
 * @param docId - The document ID.
 * @param enabled - Condition to determine if binding should occur.
 * @returns An object with the bound data and related functions.
 */
export interface UseBoundDocParams {
    path: string | undefined;
    docId: DocId | undefined;
    enabled: boolean;
}

export const useBoundDoc = <T extends DocDataWithId = DocDataWithId>({
    path,
    docId,
    enabled
}: UseBoundDocParams): UseBoundDoc<T> => {
    const { firestore } = useContext(DataContext);
    const [data, setData] = useState<T | null>(null);
    const [error, setError] = useState<Error | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true);
        if (enabled && path && docId) {
            const fullPath = `${path}/${docId}`;
            const docRef: DocumentReference = doc(firestore, fullPath);

            const unsubscribe = onSnapshot(
                docRef,
                (docSnapshot) => {
                    if (docSnapshot.exists()) {
                        setData({ 
                            docId: docSnapshot.id, 
                            ...docSnapshot.data() 
                        } as T);
                        setError(null);
                    } else {
                        setError(new Error(`Document does not exist: ${fullPath}`));
                    }
                    setLoading(false);
                },
                (error) => {
                    console.error(`Failed to bind document: ${error} | path: ${fullPath}`);
                    setError(error);
                    setLoading(false);
                }
            );

            return () => {
                unsubscribe();
            };
        } else {
            setLoading(false);
        }
    }, [enabled, path, docId, firestore]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateData = useCallback(
        debounce((updates: Partial<T>) => {
            if (path && docId) {
                const fullPath = `${path}/${docId}`;
                setDoc(doc(firestore, fullPath), updates, { merge: true });
            }
        }, 350),
        [path, docId, firestore]
    );

    const debouncedSet = useCallback((updates: Partial<T>) => {
        setData(prevData => prevData ? {
            ...prevData,
            ...updates,
            fields: {
                ...prevData.fields,
                ...(updates.fields || {}),
            },
        } : null);
        updateData(updates);    
    }, [setData, updateData]);

    return { 
        data, 
        error, 
        loading, 
        debouncedSet 
    };
};