import { useState, useEffect, useCallback } from 'react';
import { serverTimestamp } from 'firebase/firestore';
import { useSet } from './useSet';
import { useGet } from './useGet';

/**
 * Represents the access permissions for a document.
 */
export interface DocumentAccess {
  /** Indicates if the user has read access to the document */
  canRead: boolean;
  /** Indicates if the user has write access to the document */
  canWrite: boolean;
  /** Indicates if the access check is still in progress */
  loading: boolean;
  /** Contains any error that occurred during the access check */
  error: Error | null;
}

/**
 * Custom hook to check read and write access for a specific Firestore document.
 * 
 * @param path - Firestore collection path
 * @param docId - Document ID within the collection
 * @returns {Object} Access information and loading state
 * @property {boolean} canRead - Whether the user has read access
 * @property {boolean} canWrite - Whether the user has write access
 * @property {boolean} isLoading - Whether the access check is in progress
 * @property {Error | null} error - Any error that occurred during the check
 */
export const useCheckDocAccess = (path: string | null, docId: string | null): DocumentAccess => {
  const [access, setAccess] = useState<DocumentAccess>({
    canRead: false,
    canWrite: false,
    loading: false,
    error: null,
  });

  const { get } = useGet();
  const { set } = useSet();

  const checkDocAccess = useCallback(async () => {
    if (!path || !docId) {
      setAccess({ canRead: false, canWrite: false, loading: false, error: null });
      return;
    }

    setAccess(prev => ({ ...prev, loading: true }));

    let canRead = false;
    let canWrite = false;
    let error: Error | null = null;

    try {
      const doc = await get(path, docId);
      canRead = doc !== null;
    } catch (readError) {
      error = readError as Error;
    }

    try {
      await set(path, docId, { _testField: serverTimestamp() });
      canWrite = true;
    } catch (writeError) {
      if (!error) error = writeError as Error;
    }

    setAccess({ canRead, canWrite, loading: false, error });
  }, [path, docId, get, set]);

  useEffect(() => {
    checkDocAccess();
  }, [checkDocAccess]);

  return access;
};