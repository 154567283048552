import React from 'react';

import FlexPage from '../components/atoms/FlexPage';
import FlexContent from '../components/atoms/FlexContent';
import FlexBox from '../components/atoms/FlexBox';
import Navb from '../components/Navb';
import ModelInput from '../components/agents/ModelInput';
import MessagesList from '../components/agents/MessagesList';
import ActionCards from '../components/agents/ActionCards';

const AgentPage: React.FC = () => {
    return (
        <FlexPage style={{ alignItems: 'center' }}>
            <Navb />
            <FlexContent 
                style={{ maxWidth: 1280 }} 
                alignEnd
                justifyEnd
                gap={0}
            >
                <FlexBox
                    gap={0}
                    style={{
                        justifyContent: 'flex-end',
                        flexWrap: 'wrap-reverse',
                        alignItems: 'flex-start',
                    }}
                >
                    <MessagesList />
                    <ActionCards />
                </FlexBox>
                <ModelInput />
            </FlexContent>
        </FlexPage>
    );
};

export default AgentPage;