import { FC } from 'react';
import { List, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { ListProps } from 'antd/es/list';

import { TableDataType, tableTypeOf } from '../../types/System.types';
import CellEdit from './CellEdit';
import RowActions from './RowActions';

interface ListEditProps<T> extends Omit<ListProps<T>, 'renderItem'> {
    onCellChange: (index: number, key: string | undefined, value: TableDataType) => void;
    readOnly?: boolean;
    onAddRow?: () => void;
    onRemoveRow?: (index: number) => void;
}

const ListEdit: FC<ListEditProps<TableDataType>> = ({ 
    dataSource, 
    onCellChange, 
    readOnly, 
    onAddRow,
    onRemoveRow,
    ...props 
}) => {
    return (
        <List
            {...props}
            dataSource={dataSource}
            renderItem={(item: TableDataType, i) => (
                <List.Item
                    key={i}
                    actions={[
                        <RowActions
                            onRemoveRow={() => onRemoveRow?.(i)}
                            disabled={readOnly}
                        />,
                    ]}
                >
                    <CellEdit
                        value={item}
                        type={tableTypeOf(item)}
                        onChange={(newValue) => {
                            if (!readOnly) {
                                onCellChange(i, undefined, newValue);
                            }
                        }}
                        readOnly={readOnly}
                    />
                </List.Item>
            )}
            footer={
                <Button 
                    onClick={onAddRow} 
                    type="link"
                    size='small'
                    icon={<PlusOutlined />}
                    disabled={readOnly || !onAddRow}
                >
                    Add Item
                </Button>
            }
        />
    );
};

export default ListEdit;