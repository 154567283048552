import React, { useContext } from 'react';
import { List, Typography, theme } from 'antd';
import { UserOutlined, DeploymentUnitOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';

import { AIContext } from '../../providers/AIProvider';
import FlexBox from '../atoms/FlexBox';

const { useToken } = theme;
const { Text } = Typography;

const MessagesList: React.FC = () => {
  const { messages, activeMessageContent } = useContext(AIContext);
  const { token } = useToken();

  const getIcon = (sender: string) => {
    return (
      <FlexBox
        noGrow
        style={{
          color: token.colorPrimary,
          minHeight: 28
        }}
      >
        {sender === 'user' ? <UserOutlined /> : <DeploymentUnitOutlined />}
      </FlexBox>
    );
  };

  return (
    <FlexBox
      column
      stretch
      justifyEnd
      alignEnd
      style={{
        minWidth: 300,
      }}
    >
      <List
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          flexGrow: 1,
        }}
        dataSource={messages}
        renderItem={(item) => (
          <List.Item key={item.id}>
            <FlexBox alignStart>
              {getIcon(item.sender)}
              <FlexBox stretch>
                <Text>
                  {item.sender === 'user' ?
                    <b>You</b>
                    :
                    <b>Agent</b>
                  }
                  <ReactMarkdown>
                    {item.content}
                  </ReactMarkdown>
                </Text>
              </FlexBox>
            </FlexBox>
          </List.Item>
        )}
      />
      <FlexBox
        column
        stretch
        justifyEnd
        alignStart
        noGrow
        style={{
          paddingLeft: 28,
        }}
      >
        <Text type='secondary'>
          <ReactMarkdown>
            {activeMessageContent}
          </ReactMarkdown>
        </Text>
      </FlexBox>
    </FlexBox>
  );
};

export default MessagesList;