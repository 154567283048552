import React, { useContext } from 'react';
import {
  Modal,
  Typography,
  Divider,
  Button
} from 'antd';
import Icon from '../atoms/Icon';

import FlexBox from '../atoms/FlexBox';
import { UserContext } from '../../providers/UserProvider';
import useFirebaseAuth from '../../use/useFirebaseAuth';

const { Title, Text } = Typography;

interface SettingsModalProps {
  visible: boolean;
  onClose: () => void;
}

const SettingsModal: React.FC<SettingsModalProps> = ({ visible, onClose }) => {
  const { user } = useContext(UserContext);
  const { logout } = useFirebaseAuth();

  return (
    <Modal
      open={visible}
      title="Settings"
      onCancel={onClose}
      footer={[
        <FlexBox>
        <Button
          type='default'
          icon={<Icon.LogOut />}
          onClick={logout}
        >
          Logout
        </Button>
        <Button
          key="submit"
          type="primary"
          onClick={onClose}
        >
            Done
          </Button>
        </FlexBox>
      ]}
    >
      <Divider />
      <Title level={5}>
        User
      </Title>
      <Text>
        {user?.email}&nbsp;
      </Text>
      <Text type='secondary'>
        <small>{user?.uid}</small><br />
      </Text>
      <Divider />
    </Modal>
  );
};

export default SettingsModal;