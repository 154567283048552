import { useBoundCollection } from './data/useBoundCollection';
import { FormType } from '../types/System.types';

export const useFormTypes = () => {
    const formTypes = useBoundCollection<FormType>({
        path: 'formTypes',
        initialOrderBy: [{ field: 'meta.created', direction: 'desc' }],
    });

    return formTypes;
}; 