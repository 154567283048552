import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

import { UserContext } from './UserProvider'; 
import { callAgentFB, callLanguageModel } from '../services/agentFunctions';
import { callRunLanguageModel } from '../services/cloudRun';

interface ChatMessage {
  id: string;
  sender: string;
  content: string | null;
  timestamp: Date | null;
}

export interface ActionCardType {
  id: string;
  title: string;
  content: string;
  actionType: ActionType;
  prompt: string;
  actionLabel?: String;
}

export interface AIContextType {
  messages: ChatMessage[];
  addMessage: (message: ChatMessage) => void;
  clearMessages: () => void;
  callAgent: (role: string, useCompanyInfo: boolean, inputValue: string) => Promise<void>;
  callModel: (role: string, useCompanyInfo: boolean, inputValue: string) => Promise<void>;
  streamModel: (role: string, useCompanyInfo: boolean, inputValue: string) => Promise<void>;
  roleOptions: { label: string; value: string }[];
  inputPrompt: string;
  setInputPrompt: React.Dispatch<React.SetStateAction<string>>;
  actionCards: ActionCardType[];
  setActionCards: React.Dispatch<React.SetStateAction<ActionCardType[]>>;
  ActionType: typeof ActionType;
  activeMessage: number | null;
  setActiveMessage: React.Dispatch<React.SetStateAction<number | null>>; 
  activeMessageContent: string | null;
  setActiveMessageContent: React.Dispatch<React.SetStateAction<string | null>>; 
}

const generalInstructions = ' You are serious and to the point. ';

type RoleType = 'Architect' | 'Corporate Attorney' | 'Civil Engineer' | 'Corporate Assistant';

export enum ActionType {
  OpenModal = 'OpenModal',
}

const actionCardsData: Record<RoleType, ActionCardType[]> = {
  'Architect': [],
  'Corporate Attorney': [
    {
      id: '1',
      title: 'Review Contract',
      content: 'Review a contract for legal risks.',
      prompt: 'Review the following contract for risks and advice. Output Markdown. Quote the original document using Markdown Blockquotes.',
      actionType: ActionType.OpenModal,
    },
    {
      id: '2',
      title: 'Revise Contract',
      content: 'Make favorable revisions to the contract.',
      prompt: 'Modify the following contract in a way that is favorable to your organization. Output Markdown. Quote the original document using Markdown Blockquotes.',
      actionType: ActionType.OpenModal,
    },
  ],
  'Civil Engineer': [],
  'Corporate Assistant': [
    {
      id: '1',
      title: 'Manipulate Document',
      content: 'Use AI to create or modify a document.',
      prompt: '',
      actionType: ActionType.OpenModal,
    },
  ],
};

const defaultCompanyContext = ``; 

const roleOptions = [
  {
    label: 'Operations Manager',
    value: 'Operations Manager',
  },
  {
    label: 'Civil Engineer',
    value: 'Civil Engineer',
  },
  {
    label: 'Lighting Architect',
    value: 'Lighting Architect',
  },
  {
    label: 'Corporate Assistant',
    value: 'Corporate Assistant',
  },
];

const defaultMessage: ChatMessage = {
  id: 'welcome_1',
  sender: 'model',
  content: 'Hello. How can I help you today?',
  timestamp: new Date(),
};

export const AIContext = createContext<AIContextType>({
  messages: [],
  addMessage: () => {},
  clearMessages: () => {},
  callAgent: async () => {},
  callModel: async () => {},
  streamModel: async () => {},
  roleOptions: [],
  inputPrompt: '',
  setInputPrompt: () => {},
  actionCards: [],
  setActionCards: () => {},
  ActionType: ActionType,
  activeMessage: null,
  setActiveMessage: () => {},
  activeMessageContent: null,
  setActiveMessageContent: () => {},
});

export const AIProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { 
    settings,
  } = useContext(UserContext);
  const [messages, setMessages] = useState<ChatMessage[]>([defaultMessage]);
  const [inputPrompt, setInputPrompt] = useState('');
  const [actionCards, setActionCards] = useState<ActionCardType[]>([]);
  const [activeMessageContent, setActiveMessageContent] = useState<string | null>(null);
  const [activeMessage, setActiveMessage] = useState<number | null>(null);
  const orgnizationContext = defaultCompanyContext;
  
  useEffect(() => {
    setActionCards(actionCardsData[settings?.role as RoleType]);
  }, [settings?.role]);

  const addMessage = (message: ChatMessage) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  const clearMessages = () => {
    setMessages([defaultMessage]);
  };

  const callAgent = async (role: string, useCompanyInfo: boolean, inputValue: string) => {
    try {
      const response = await callAgentFB({
        system: 'You are an ' + role + ' ' + generalInstructions + (useCompanyInfo ? orgnizationContext : ''),
        prompt: inputValue
      });

      addMessage({
        id: `user_${new Date().toISOString()}`,
        sender: 'user',
        content: inputValue,
        timestamp: new Date(),
      });

      addMessage({
        id: `response_${new Date().toISOString()}`,
        sender: 'model',
        content: response.result.output,
        timestamp: new Date(),
      });
    } catch (e) {
      console.error(e);
    }
  }
  const callModel = async (role: string, useCompanyInfo: boolean, inputValue: string) => {
    try {
      const response = await callLanguageModel({
        system: 'You are an ' + role + ' ' + generalInstructions + (useCompanyInfo ? orgnizationContext : ''),
        prompt: inputValue,
      });

      addMessage({
        id: `user_${new Date().toISOString()}`,
        sender: 'user',
        content: inputValue,
        timestamp: new Date(),
      });

      addMessage({
        id: `response_${new Date().toISOString()}`,
        sender: 'model',
        content: response.completion.choices[0].message.content,
        timestamp: new Date(),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const streamModel = async (role: string, useCompanyInfo: boolean, inputValue: string) => {
    try {
      setActiveMessageContent('');
      let response = '';

      addMessage({
        id: `user_${new Date().toISOString()}`,
        sender: 'user',
        content: inputValue,
        timestamp: new Date(),
      });

      await callRunLanguageModel({
        system: 'You are an ' + role + ' ' + generalInstructions + (useCompanyInfo ? orgnizationContext : ''),
        prompt: inputValue,
        model: 'gpt-4o',
      }, 
      (chunk) => { 
        response += chunk;
        setActiveMessageContent(response);
      }, 
      (error) => { 
        console.error('Error streaming from the language model:', error);
      }, 
      () => { 
        addMessage({
          id: `stream_end_${new Date().toISOString()}`,
          sender: 'model',
          content: response,
          timestamp: new Date(),
        });
        setActiveMessageContent('');
      });

    } catch (error) {
      console.error('Error initiating stream from the language model:', error);
    }
  };

  return (
    <AIContext.Provider value={{
      messages,
      addMessage,
      clearMessages,
      callAgent,
      callModel,
      streamModel,
      roleOptions,
      inputPrompt,
      setInputPrompt,
      actionCards,
      setActionCards,
      ActionType,
      activeMessageContent,
      setActiveMessageContent,
      activeMessage,
      setActiveMessage,
    }}>
      {children}
    </AIContext.Provider>
  );
};
