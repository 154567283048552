import { httpsCallable } from 'firebase/functions';

import { FormStateId, FormVersion, Form } from '../types/System.types';
import { functions } from './firebaseFunctions';


/**
 * Request object for creating a new form version.
 */
interface CreateFormVersionRequest {
    formId: string;
    newState: FormStateId;
    currentVersionData: FormVersion;
}

/**
 * Creates a new form version using Firebase Cloud Functions.
 * @param {CreateFormVersionRequest} requestData - The data required to create a new form version.
 * @returns {Promise<string>} A promise that resolves to the ID of the created form version.
 * @throws {Error} If the form version creation fails.
 */
export const createFormVersion = async (
    formId: string,
    newState: FormStateId,
    currentVersionData: FormVersion
): Promise<string> => {
    const callCreateFormVersion = httpsCallable<CreateFormVersionRequest, string>(functions, 'createFormVersion');

    const requestData: CreateFormVersionRequest = {
        formId,
        newState,
        currentVersionData
    };

    try {
        const result = await callCreateFormVersion(requestData);
        console.log('New form version created:', result.data);
        return result.data;
    } catch (error) {
        console.error('Error creating form version:', error);
        throw new Error(`Failed to create form version: ${error}`);
    }
};


/**
 * Request object for creating a new form.
 */
export interface CreateFormRequest {
    formType: string;
    title?: string;
    organization?: string;
    userGroup?: string;
    setFormData?: Partial<Form>;
    setVersionData?: Partial<FormVersion>;
}

/**
 * Creates a new form using Firebase Cloud Functions.
 * @param {CreateFormRequest} requestData - The data required to create a new form.
 * @returns {Promise<string>} A promise that resolves to the ID of the created form.
 * @throws {Error} If the form creation fails.
 */
export const createForm = async (requestData: CreateFormRequest): Promise<string> => {
    const callCreateForm = httpsCallable<CreateFormRequest, string>(functions, 'createForm');

    try {
        const result = await callCreateForm(requestData);
        return result.data;
    } catch (error) {
        const errorMessage = `Failed to create form: ${error}`;
        console.error(`Error calling createForm function:`, error);
        throw new Error(errorMessage);
    }
};

/**
 * Request object for creating a new form from an existing form.
 */
interface CreateFormFromFormRequest {
    formId: string;
    newFormType: string;
    title?: string;
    organization?: string;
    userGroup?: string;
    setFormData?: Partial<Form>;
    setVersionData?: Partial<FormVersion>;
}

/**
 * Creates a new form from an existing form using Firebase Cloud Functions.
 * @param {CreateFormFromFormRequest} requestData - The data required to create a new form from an existing form.
 * @returns {Promise<string>} A promise that resolves to the ID of the created form.
 * @throws {Error} If the form creation fails.
 */
export const createFormFromForm = async (requestData: CreateFormFromFormRequest): Promise<string> => {
    if (!functions) {
        throw new Error('Firebase Functions not initialized');
    }

    const callCreateFormFromForm = httpsCallable<CreateFormFromFormRequest, string>(functions, 'createFormFromForm');

    try {
        const result = await callCreateFormFromForm(requestData);
        return result.data;
    } catch (error) {
        const errorMessage = `Failed to create form from existing form: ${error}`;
        console.error(`Error calling createFormFromForm function:`, error);
        throw new Error(errorMessage);
    }
};